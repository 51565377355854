<template>
    <g v-if="item" class="map-location-marker" :class="[variant, {'different-floor': !isSameFloor}]" :transform="getTransform">
        <g class="fixed-px-size">
            <template v-if="outerRadius">
                <circle @mousedown.self="$emit('selected', item)" class="circle-around" stroke="3" r="40"/>
                <g v-if="item.angle < 0 || item.angle > 0" :transform="`translate(0 0) rotate(${item.angle - 180}) scale(35)`">
                    <path class="circle-orientation" d="M -0.1 0 L -0.5 1 Q 0 1.3 0.5 1 L 0.1 0 Z" style="opacity: 1"/>
                </g>
            </template>

            <circle v-if="shape === 'circle'" @mousedown.self="$emit('selected', item)" class="circle-marker" stroke="3" r="10"/>
            <template v-else-if="shape === 'arrow'">
                <g :transform="`translate(0 0) rotate(${angle - 180}) scale(27)`">
                    <path class="arrow-marker" d="M -0.6 -0.5 L 0 1 L 0.6 -0.5 L 0 -0.25 Z" style="stroke: white; stroke-width: 0.1px; stroke-linejoin: round;"/>
                </g>
            </template>

            <template v-if="label">
                <rect x="-75" y="33" width="150" rx="8" ry="8" height="32" fill="#eee" style="opacity: 0.85"/>
                <text y="40" font-size="20" dominant-baseline="hanging" text-anchor="middle">
                    {{ tr(label) }}
                </text>
            </template>
        </g>
    </g>
</template>

<script>
import MapHelper from "@/helpers/maps/MapHelper.js";
import MapMixin from "@/helpers/maps/map-mixin.js";

export default {
    name: `map-location-marker`,
    mixins: [MapMixin],
    props: {
        item: {type: Object, required: true},
        variant: {type: String, default: `secondary`},
        angle: {type: [String, Number]},
        shape: {type: String, default: `circle`}, // circle or arrow
        outerRadius: {type: Boolean},
        floor: {type: Object, required: true},
        label: {type: String}
    },
    data() {
        return {
            MapHelper
        }
    },

    computed: {
        isSameFloor() {
            return this.item.floorId === this.floor.id;
        },
        getTransform() {
            return `translate(${this.item.x} ${this.item.y})`;
        }
    }
}
</script>

<style lang="scss" scoped>
.map-location-marker {
    z-index: 10;
    transition: 500ms;

    .circle-around,
    .circle-marker {
        stroke: white;
        stroke-width: 2px;
        fill: var(--map-secondary-color);
    }

    .circle-around {
        opacity: 0.2;
    }

    text {
        font-weight: 700;
        fill: var(--map-secondary-color);
        user-select: none;
    }

    .circle-orientation,
    .arrow-marker {
        fill: var(--map-secondary-color);
    }

    &.primary {
        .circle-around,
        .circle-marker,
        .circle-orientation,
        .arrow-marker,
        text {
            fill: var(--map-primary-color);
        }
    }

    &.different-floor {
        display: none;
        opacity: 0.5;
    }
}
</style>
