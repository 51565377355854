<template>
    <div class="map-destination-reached">
<!--        <b-img :src="require(`@/assets/icons/wayfinding/direction-destination-reached.svg`)"/>-->
        <span v-tr>Arrived at Destination|Arrivée à destination</span>
    </div>
</template>

<script>
// map-destination-reached #hot-reload-debug
export default {
    name: `map-destination-reached`
}
</script>

<style lang="scss" scoped>
.map-destination-reached {
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        background-color: #4CAF50;
        padding: 3px 12px;
        color: white;
        font-weight: bold;
        font-size: 18pt;
        border-radius: 0 0 8px 8px;
        border: 2px solid white;
        border-top-width: 0;
    }

    img {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
</style>
