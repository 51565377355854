<template>
    <g v-if="navPath && navPathShape" class="layer layer-map-nav-path" :style="navStyle">

        <template v-if="isPathDebugVisible || (instruction && instruction.index > 0)">
            <g v-for="(link, index) in navPath.links" :key="'link-'+index">
                <template v-if="isSameFloor(link) || isPathDebugVisible">
                    <line class="map-nav-segment"
                          :class="{'active': isActive(link), 'hidden': !link.visible, 'different-floor': !isSameFloor(link)}"
                          :x1="link.masterItem.x" :y1="link.masterItem.y"
                          :x2="link.slaveItem.x" :y2="link.slaveItem.y"/>

                    <text v-if="isPathDebugVisible" :x="link.getMiddlePoint().x" :y="link.getMiddlePoint().y" font-weight="bold" :style="{fontSize: getScaleCSS(16)}">
                        {{link.angle}}° {{link.distance|round}}m
                    </text>
                </template>
            </g>
        </template>
        <template v-else>
            <path :d="navPathShape.path" class="map-nav-path" fill="transparent"/>
        </template>
        <path :d="navPathShape.path" class="map-nav-path-dot-animation" fill="transparent"/>
    </g>
</template>

<script>
import MapMixin from "@/helpers/maps/map-mixin.js";
export default {
    name: `layer-map-nav-path`,
    mixins: [MapMixin],
    props: {
        linkSelected: { type: Object },
        instruction: { type: Object },
        floor: { type: Object },
        navPath: { type: Object }
    },
    computed: {
        navStyle() {
            return {
                "--nav-path-length": this.navPathShape.distance,
                "--nav-path-duration": `${this.navPathShape.duration}s`
            }
        },
        navPathShape() {
            return this.navPath.getFloorPath(this.floor.id);
        }
    },
    methods: {
        isSameFloor(link) {
            return link.floorId === this.floor.id;
        },
        isActive(link) {
            return this.isPathDebugVisible && this.instruction && this.instruction.links.includes(link);
        }
    }
}
</script>

<style lang="scss" scoped>
.layer-map-nav-path {
    z-index: 0;

    --nav-path-length: 100;
    --nav-path-duration: 10s;

    .map-nav-path-dot-animation {
        stroke: var(--map-primary-color);
        stroke-linecap: round;
        stroke-linejoin:round;
        stroke-width: calc(18 * var(--px-to-svg));
        stroke-dasharray: 0, var(--nav-path-length);
        stroke-dashoffset: var(--nav-path-length);
        animation: dash var(--nav-path-duration) linear infinite;
        animation-delay: 1s;
    }

    .map-nav-segment {
        stroke: var(--map-primary-color);
        stroke-linecap: round;
        stroke-linejoin:round;
        stroke-width: calc(8 * var(--px-to-svg));

        &.active {
            stroke: var(--map-secondary-color);
        }

        &.hidden {
            display: none;
            stroke-width: calc(8 * var(--px-to-svg));
            stroke-dasharray: 0, calc(16 * var(--px-to-svg));
        }

        &.different-floor {
            opacity: 0.5;
            stroke-dasharray: 1, 2;
        }
    }

    .map-nav-path {
        stroke: var(--map-primary-color);
        stroke-linecap: round;
        stroke-linejoin:round;
        stroke-width: calc(8 * var(--px-to-svg));
        stroke-dasharray: var(--nav-path-length);
        stroke-dashoffset: var(--nav-path-length);
        animation: dash var(--nav-path-duration) linear forwards;
        animation-delay: 1s;

        &.active {
            stroke: var(--map-secondary-color);
        }

        &.different-floor {
            opacity: 0.5;
            stroke-dasharray: 1, 2;
        }
    }

    @keyframes dash {
        to {
            stroke-dashoffset: 0;
        }
    }
}
</style>
