<template>
    <div class="map-trajectory-search">
        <div class="back-button">
            <b-btn @click="hide" variant="transparent-light" pill>
                <i class="fa-chevron-left" :class="fontAwesomePrefix"/>
            </b-btn>
        </div>
        <div class="path-icon">
            <i class="fa-dot-circle" :class="fontAwesomePrefix"/>
            <i class="fa-ellipsis-v my-2" :class="fontAwesomePrefix"/>
            <i class="fa-map-pin" :class="fontAwesomePrefix"/>
        </div>
        <div class="path-points">
            <b-form-group>
                <b-input-group v-if="currentLocation">
                    <b-input :value="currentLocation.getName()" style="pointer-events: none;"/>
                    <b-btn variant="translucent-light" @click="$emit(`clear-current-location`)">
                        <i class="fa-close fa-fw" :class="fontAwesomePrefix"/>
                    </b-btn>
                </b-input-group>
                <b-select v-else list="map-autocomplete" :class="{'empty': !startId && !startPoint, 'hide-disabled-state': startPoint && !startPoint.cmsItem}" v-model="startId" @change="setStartByCmsItemId">
                    <option disabled :value="undefined">
                        <template v-if="startPoint && !startPoint.cmsItem">{{startPoint.getName()}}</template>
                        <template v-else>
                            {{tr(`Select Start Point|Choisir un point de départ`)}}
                        </template>
                        </option>
                    <optgroup :label="tr('Entrances/Exits|Entrées/Sorties')">
                        <option v-for="item in entrances" :key="`start-${item.id}}`" :value="item.id" :disabled="item.id === startId || item.id === endId">
                            {{item.getName()|tr}} <template v-if="item && item.floor">- {{item.floor.getName()|tr}}</template>
                        </option>
                    </optgroup>
                    <option v-for="item in autoComplete" :key="`start-${item.id}}`" :value="item.id" :disabled="item.id === startId || item.id === endId">
                        {{item.getName()|tr}} <template v-if="item && item.floor">- {{item.floor.getName()|tr}}</template>
                    </option>
                </b-select>
            </b-form-group>
            <b-form-group class="mb-0">
<!--                <b-btn v-if="endPoint" class="btn-clear" variant="transparent-light" @click="setEnd(null)">-->
<!--                    <i class="fa-close" :class="fontAwesomePrefix"/>-->
<!--                </b-btn>-->
                <b-select list="map-autocomplete" :class="{'empty': !endId && !endPoint, 'hide-disabled-state': endPoint && !endPoint.cmsItem}" v-model="endId" @change="setEndByCmsItemId">
                    <option :value="undefined" disabled>
                        <template v-if="endPoint && !endPoint.cmsItem">{{endPoint.getName()}}</template>
                        <template v-else>
                            {{tr(`Choose Destination|Choisir un point d'arrivée`)}}
                        </template>
                    </option>
                    <optgroup :label="tr('Entrances/Exits|Entrées/Sorties')">
                        <option v-for="item in entrances" :key="`start-${item.id}}`" :value="item.id" :disabled="item.id === startId || item.id === endId">
                            {{item.getName()|tr}} <template v-if="item && item.floor">- {{item.floor.getName()|tr}}</template>
                        </option>
                    </optgroup>
                    <option v-for="item in autoComplete" :key="`end-${item.id}}`" :value="item.id" :disabled="item.id === startId || item.id === endId">
                        {{item.getName()|tr}} <template v-if="item.floor">- {{item.floor.getName()|tr}}</template>
                    </option>
                </b-select>
            </b-form-group>
        </div>

<!--        <datalist id="map-autocomplete">-->
<!--            <option v-for="item in autoComplete" :key="`autoc-${item.id}}`">{{item.name|tr}}</option>-->
<!--        </datalist>-->
        <div v-if="!currentLocation" class="actions">
            <b-btn variant="transparent-light" @click="invertNavPoints">
                <i class="fa-arrow-down-arrow-up" :class="fontAwesomePrefix"/>
            </b-btn>
        </div>
    </div>
</template>

<script>
import MapMixin from "@/helpers/maps/map-mixin.js";
import EPanel from "../../../../../vue-components/components/e-panel.vue";

/*
This component is using CMS Item because some CmsItems (such as toilet) might have more than one MapItem, and we don't want to display the same item 10 times in the list
so our goal is to find the closest MapItem based on the CmsItem.id
 */

// map-trajectory-search #hot-reload-bugfix
export default {
    name: `map-trajectory-search`,
    components: {EPanel},
    mixins: [MapMixin],
    props: {
        map: { type: Object },
        currentLocation: { type: Object },
        pathfinder: { type: Object },
        startPoint: { type: Object },
        endPoint: { type: Object }
    },
    data() {
        return {
            startId: ``,
            endId: ``,
        }
    },
    computed: {
        entrances() {
            if (!this.map)
                return;

            return this.reduceMapItems(this.map.items.filter(i => i.visibility !== `never` && i.type === `entrance` && i.cmsItem));
        },
        autoComplete() {
            if (!this.map)
                return;

            return this.reduceMapItems(this.map.items.filter(i => i.visibility !== `never` && i.type !== `entrance` && i.cmsItem));
        }
    },
    mounted() {
        this.setStart(this.startPoint, false);
        this.setEnd(this.endPoint, false);
    },
    methods: {
        reduceMapItems(mapItems) {
            const items = [...new Set(mapItems.map(mapItem => mapItem.cmsItem))];
            items.sort((a, b) => this.tr(a.getName()).localeCompare(this.tr(b.getName())));
            return items;
        },
        invertNavPoints() {
            const startPoint = this.startPoint;
            this.setStart(this.endPoint);
            this.setEnd(startPoint);
            this.$emit(`updated`);
        },
        findClosestItemByCmsItemId(startPoint, cmsItemId) {
            let items = this.map.getItemsByCmsItemId(cmsItemId);
            return this.pathfinder.findClosestItem(startPoint, items);
        },
        setStartByCmsItemId(cmsItemId) {
            if (!cmsItemId) {
                this.setStart(null);
            } else {
                this.setStart(this.findClosestItemByCmsItemId(this.endPoint, cmsItemId));

                // check if there is a closest map item
                if (this.startPoint && this.endPoint?.cmsItem?.hasMultipleMapItems()) {
                    this.setEnd(this.findClosestItemByCmsItemId(this.startPoint, this.endPoint.cmsItem.id));
                }
            }
        },
        setStart(value, updateValue = true) {
            if (value && value.cmsItem) {
                this.startId = value.cmsItem.id;
            } else {
                this.startId = undefined;
            }
            if (updateValue) {
                this.$emit(`update:startPoint`, value);
                this.$emit(`updated`);
            }
        },
        setEndByCmsItemId(cmsItemId) {
            if (!cmsItemId) {
                this.setEnd(null);
            } else {
                this.setEnd(this.findClosestItemByCmsItemId(this.startPoint, cmsItemId));

                // check if there is a closest map item
                if (this.endPoint && this.startPoint?.cmsItem?.hasMultipleMapItems()) {
                    this.setStart(this.findClosestItemByCmsItemId(this.endPoint, this.startPoint.cmsItem.id));
                }
            }
        },
        setEnd(value, updateValue = true) {
            if (value && value.cmsItem) {
                this.endId = value.cmsItem.id;
            } else {
                this.endId = undefined;
            }
            if (updateValue) {
                this.$emit(`update:endPoint`, value);
                this.$emit(`updated`);
            }
        },
        hide() {
            this.$emit(`update:startPoint`, null);
            this.$emit(`update:endPoint`, null);
            this.$emit(`updated`);
            this.$emit(`hide`);
        }
    },
    watch: {
        startPoint() {
            this.setStart(this.startPoint, false);
        },
        endPoint() {
            this.setEnd(this.endPoint, false);
        }
    }
}
</script>

<style lang="scss" scoped>
.map-trajectory-search {
    position: fixed;
    top: var(--page-padding-top);
    left: var(--page-padding-left);
    width: var(--map-assistant-width);
    max-width: 100%;
    height: 120px;
    //background-color: white;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: var(--accent-color-alt);
    color: white;
    //box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
    touch-action:none;

    .path-icon {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        justify-content: center;
        padding: 5px 10px;

        //.fa-map-pin {
        //    color: #de4a40;
        //}
    }

    .path-points {
        flex: 1;
        text-align: left;

        .form-control,
        .custom-select {
            min-width: calc(var(--map-assistant-width) - 130px);
            max-width: calc(var(--map-assistant-width) - 130px);
            padding-right: 40px;
            position: relative;
            background-color: rgba(white, 0.2);
            color: white;
            border: 1px solid rgba(white, 0.85);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &.empty {
                color: rgba(white, 0.7);
                font-style: italic;
            }

            &.hide-disabled-state {
                color: white;
                font-style: initial;
            }

            optgroup,
            option {
                background: white;
                color: #333;
                font-style: normal;

                &[disabled] {
                    color: #777;
                }
            }
        }

        .form-control {
            min-width: auto;
        }

        .btn-translucent-light {
            border: 1px solid white;
        }

        //.btn-clear {
        //    display: flex;
        //    justify-content: center;
        //    align-items: center;
        //    position: absolute;
        //    top: 0;
        //    right: 0;
        //    height: 100%;
        //}
    }

    .actions {

    }
}

.layout-mobile {
    .map-trajectory-search {
        top: var(--page-padding-top);
        left: var(--page-padding-left);
        right: var(--page-padding-right);
        padding: 10px 5px;
        z-index: 1040;
        background: var(--accent-color);
        border-radius: 0;
        box-shadow: 0 5px 15px rgba(black, 0.15);

        .empty {
            font-size: 95%;
        }
    }
}
</style>
