<template>
    <e-panel class="map-cms-item-popup" @close="close" :z-index="1"
             :x="x" :y="y" :draggable="false" :resizable="false" lock-in-parent :top-margin="5" body-class="p-0" expanded>
        <b-img class="preview-image" v-if="previewImage" :src="previewImage"/>

        <h4 class="my-0 pb-2">{{mapItem.getName()}}</h4>
<!--        <div v-if="cmsItem && tr(cmsItem.getSecondaryValue())" class="p-3">-->
<!--            <h3>{{cmsItem.getSecondaryValue()|tr}}</h3>-->
<!--        </div>-->
        <template #footer>
            <i class="fas fa-route mr-2"/>
            <b-btn class="" variant="translucent-light" @click="$emit('create-route', `start-from`)">
                <span v-tr>Start from|Partir de</span>
            </b-btn>
            <b-btn class="ml-2 text-dark" variant="white" @click="$emit('create-route', `go-to`)">
                <span v-tr>Go to|Aller à</span>
            </b-btn>
        </template>
    </e-panel>
</template>

<script>
import MapMixin from "@/helpers/maps/map-mixin.js";
import EPanel from "../../../../../vue-components/components/e-panel.vue";

export default {
    name: `map-cms-item-popup`,
    components: {EPanel},
    mixins: [MapMixin],
    props: {
        mapItem: { type: Object },
        cmsItem: { type: Object }
    },
    data() {
        return {
            width: 350,
            x: (window.innerWidth - this.width) / 2,
            y: 120
        }
    },
    computed: {
        previewImage() {
            if (this.cmsItem) {
                return this.cmsItem.getPreviewImage();
            } else {
                return this.mapItem.image;
            }
        }
    },
    methods: {
        close() {
            this.$emit(`close`);
        }
    }
}
</script>

<style lang="scss" scoped>
.map-cms-item-popup {
    width: 350px !important;
    max-width: 100% !important;
    --preview-image-size: 130px;
    top: 120px;
    left: calc(50% - 350px / 2);
    margin: 0;

    .preview-image {
        position: relative;
        left: 10px;
        border: 4px solid var(--accent-color);
        border-radius: 8px;
        margin-top: -140px;
        width:  var(--preview-image-size);
        height: var(--preview-image-size);
    }

    ::v-deep .panel-header {
        background-color: var(--accent-color);
        color: white;
    }

    ::v-deep .panel-body {
        border-radius: 0;
        background-color: var(--accent-color);
        color: white;
    }

    ::v-deep .panel-footer {
        justify-content: center;
        background-color: var(--accent-color);
        color: white;
    }
}
</style>
