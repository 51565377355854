<template>
    <g v-if="item" :visibility="isVisible ? 'visible': 'hidden'" class="map-item" :z-index="item.zIndex" :transform="getTransform"
       :class="['type-' + item.type, `size-${item.size || 'md'}`, { 'item-selected': selected, 'disable-selection': !item.selectable, 'active': active }]">
        <g class="fixed-px-size">
            <circle @mousedown.self="$emit('selected', item)" class="selector" :class="{ 'hidden': !currentMap.style.iconCircleVisible }"/>

<!--            <g v-if="item.cmsItem?.glyph">-->
<!--                <circle @mousedown.self="$emit('selected', item)" class="icon-circle"/>-->
<!--                <image class="icon rounded fas" :href="require(`@fortawesome/fontawesome-pro/svgs/solid/${item.cmsItem.glyph.replace(`fa-`, ``)}.svg`)"/>-->
<!--            </g>-->
            <image class="icon" @mousedown.self="$emit('selected', item)" :href="item.getIcon()"/>

            <text v-if="isLabelVisible" class="map-label" :class="`position-${item.labelPosition || 'auto'}`" :x="labelX" :y="labelY" stroke="6" text-anchor="middle" paint-order="stroke" dominant-baseline="hanging" >
                <template v-if="isMapDebugVisible">[{{item.index}}]</template>
                {{item.getName()}}
            </text>
        </g>
    </g>
</template>

<script>
import MapHelper from "@/helpers/maps/MapHelper.js";
import MapMixin from "@/helpers/maps/map-mixin.js";

// map-item-point
export default {
    name: `map-item-point`,
    mixins: [MapMixin],
    inject: [`zoomLevel`],
    props: {
        item: { type: Object },
        floor: { type: Object },
        layers: { type: Object },
        navEnabled: {type: Boolean},
        active: { type: Boolean },
        selected: { type: Boolean },
        showStats: { type: Boolean },
        rangeDisplay: { type: String, default: `long` },
        apStats: { type: Object }
    },
    data() {
        return {
            MapHelper
        }
    },
    computed: {
        getTransform() {
            return `translate(${this.item.x} ${this.item.y})`;
        },
        labelX() {
            switch (this.item.labelPosition) {
                case `left`:
                    return -15;
                case `right`:
                    return 15;
                default:
                    return 0;
            }
        },
        labelY() {
            switch (this.item.labelPosition) {
                case `top`:
                    return -25;
                case `left`:
                case `right`:
                    return 0;
                default:
                    return 25;
            }
        },
        isLabelVisible() {
            if (this.isMapDebugVisible) {
                return true;
            }
            switch (this.item.labelVisibility) {
                case `auto`:
                    if (!this.active && this.item.zoomLevel !== `always` &&
                        (this.item.zoomLevel >= 0 && this.item.zoomLevel > this.zoomLevel - 2)) {
                        return false;
                    } else {
                        return this.item.category === `location` || this.active;
                    }
                case `always`:
                    return true;
                case `active`:
                    return this.active;
                // including never
                default:
                    return false;
            }
        },
        isVisible() {
            if (this.isMapDebugVisible) {
                return true;
            }

            // hide items not concerned by nav path
            if (this.navEnabled && !this.active && this.item.visibility !== `always`) {
                return false;
            }

            if (!this.active && this.item.zoomLevel !== `always` &&
                (this.item.zoomLevel >= 0 && this.item.zoomLevel > this.zoomLevel)) {
                return false;
            }

            switch (this.item.visibility) {
                case `auto`:
                    return this.item.type !== `nav-point` && this.item.type !== `qr-code` && this.item.type !== `directions`;
                case `always`:
                    return true;
                case `active`:
                    return this.active;
                // including never
                default:
                    return false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.map-item {
    z-index: 1;
    cursor: pointer;
    --icon-scale: 1;

    --icon-size: calc(var(--map-icon-size) * var(--icon-scale));
    --translate-transform: translate(calc(var(--icon-size) / -2), calc(var(--icon-size) / -2));

    &.disable-selection,
    .icon {
        pointer-events: none;
    }

    .icon {
        pointer-events: none;
        width: var(--icon-size);
        height: var(--icon-size);
        transform: var(--translate-transform);
    }

    // experiment
    circle.icon-circle {
        r: calc(var(--map-icon-size) * 0.5);
        fill: url(#iconBG) yellow;
        stroke: #455057;
        stroke-width: 1px;
    }

    .rounded.icon {
        width: calc(var(--icon-size) * 0.7);
        height: calc(var(--icon-size) * 0.7);
        transform: translate(calc(var(--icon-size) / -2 * 0.7), calc(var(--icon-size) / -2 * 0.7));
        filter: invert(0.9) drop-shadow(0 0 1px black);
    }

    &.size-xs {
        --icon-scale: 0.7;
    }
    &.size-sm {
        --icon-scale: 0.8;
    }
    &.size-lg {
        --icon-scale: 1.1;
    }
    &.size-xl {
        --icon-scale: 1.2;
    }
    &.size-xxl {
        --icon-scale: 1.5;
    }

    circle.selector {
        r: calc(var(--map-icon-size) * 0.85);
        fill: rgba(255, 255, 255, 0.2);
        stroke: rgba(255, 255, 255, 0.1);

        &.hidden {
            fill: transparent;
            stroke: transparent;
        }

        stroke-width: 0.1px;

        &:hover {
            fill: rgba(var(--selection-color), 0.3) !important;
            stroke: rgb(var(--selection-color)) !important;
        }
    }

    &:hover {
        .icon {
            transform: scale(1.2) var(--translate-transform);
        }
    }

    &.active {
        .icon {
            transform: scale(1.5) var(--translate-transform);
        }
    }

    .map-label {
        z-index: 2;
        alignment-baseline: middle;
        user-select: none;
        pointer-events: none;
        fill: #333;
        font-size: var(--map-label-size);
        font-weight: bold;
        text-align: left;
        stroke: white;
        text-anchor: middle;
        paint-order: stroke;
        dominant-baseline: hanging;

        //text-shadow: 2px 2px 5px black;

        &.position-left {
            text-anchor: end;
        }

        &.position-right {
            text-anchor: start;
        }

        &.auto-hide {
            //display: none;
        }
    }
}
</style>
