import DateMixin from "../../mixins/DateMixin.js";
import Model, {type} from "../../abstract/Model.js"

export default class CmsNavLog extends Model {
    static collection = `cms_nav_logs`;
    static endpoint = `/api/public/nav-logs`;
    static primaryKey = [`dealerid`, `startPoint.id`, `endPoint.id`];

    static mixins = [DateMixin]

    dealerid = {
        [type]: String,
        flags: [F_LOWER],
        required: true,
        index: 1
    }

    startPoint = {
        [type]: Object,
        required: true
    }

    endPoint = {
        [type]: Object,
        required: true
    }

    started = {
        [type]: Number,
        default: () => 0,
        required: true,
        incrementOnly: true
    }

    finished = {
        [type]: Number,
        default: () => 0,
        required: true,
        incrementOnly: true
    }

    constructor() {
        super(...arguments);
    }
}
