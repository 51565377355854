<template>
    <div class="pano-360" :class="{ 'fullscreen': fullscreen }">
        <map-destination-reached v-if="isArrived"/>

        <div class="pano-360-container rounded" :style="{ pointerEvents: modeAR ? 'none' : 'auto' }" ref="pano-360-container"
             @pointerdown="onMouseDown" @touchmove="onTouchMove" @mousemove="onMouseMove" @pointerup="onMouseUp">
            <div v-if="isTouchAnimationVisible">
<!--                <div class="animated-layer"/>-->
                <img class='animated-finger' src="@/assets/icons/touch-hint.svg"/>
            </div>
        </div>

        <b-btn v-if="isMobileLayoutOrPreview" :variant="modeAR ? 'primary' : 'light'" class="btn-toggle-ar"
               :class="{ 'active': modeAR }" @click="toggleARMode">
            <i class="fas" :class="modeAR ? 'fa-compass' : 'fa-compass-slash'"/>
        </b-btn>

        <div class="debug" v-if="isPanoDebugVisible && cameraRotation && camera">
            <b-badge class="mb-1">C-Rot:
                {{ cameraRotation.x|round }},{{ cameraRotation.y|round }},{{ cameraRotation.z|round }}
            </b-badge>
            <b-badge class="mb-1">C-Pos:
                {{ roundFloat(camera.position.x) }},{{ roundFloat(camera.position.y) }},{{ roundFloat(camera.position.z) }}
            </b-badge>
            <b-badge class="mb-1">Camera: {{ cameraAngle|round }}</b-badge>
            <b-badge class="mb-1">Acc:
                {{ Math.round(accelerometer.x) }},{{ Math.round(accelerometer.y) }},{{ Math.round(accelerometer.z) }}
            </b-badge>
            <b-badge class="mb-1">Image: {{ imgAngle|round }}</b-badge>
            <b-badge class="mb-1">From: {{ fromAngle|round }}</b-badge>
            <b-badge class="mb-1">To: {{ toAngle|round }}</b-badge>
        </div>
    </div>
</template>

<script>
import MapDestinationReached from "@/components/maps/map-destination-reached.vue";
import MapMixin from "@/helpers/maps/map-mixin.js";
import MapMathHelper from "@/helpers/maps/MapMathHelper.js";
import * as THREE from 'three';

// pano-360 #hot-reload-debug
export default {
    name: `pano-360`,
    components: {MapDestinationReached},
    mixins: [MapMixin],
    props: {
        src: {type: String, required: true},
        isArrived: {type: Boolean},
        fullscreen: {type: Boolean},
        arrowColor: {type: String, default: `#2297ff`},
        // modeAR: {type: Boolean},
        imgAngle: {type: [String, Number], default: 0},
        fromAngle: {type: Number, default: 0},
        toAngle: {type: Number, default: 0},
        isFirstStep: {type: Boolean},
    },
    data() {
        return {
            loading: false,
            container: null,
            controls: null,
            scene: null,
            camera: null,
            cameraRotation: {x: 0, y: 0, z: 0},
            accelerometer: {x: 0, y: 0, z: 0},
            renderer: null,
            cameraAngle: 0,
            modeAR: false,
            arrow: null,
            raycaster: null,
            mouse: null,
            width: null,
            height: null,
            isMouseDown: false,
            lastMouseX: null,
            lastMouseY: null,
            rotationSpeed: 0.25, // degrees / px
            orbitRadius: 1,
            cameraAnimationCancelled: false, // camera animation
            touchAnimationHidden: false, // touch animation
            touchAnimationLoopCount: 0,
            touchAnimationAlreadyShown: false,
            touchAnimationIterationCount: 0
        }
    },
    computed: {
        normalizedArrowAngle() {
            return MapMathHelper.normalizeAngle(this.toAngle);
        },
        /**
         * Value to compensate the orientation of the floor plan with the North
         * Also apply a fix to the image if it wasn't oriented properly
         * @returns {number}
         */
        angleFix() {
            return this.currentMap.world.northAngle;
        },
        isTouchAnimationVisible() {
            if (isKioskDevice) {
                return this.isFirstStep && !this.touchAnimationHidden;
            } else {
                return this.isFirstStep && !this.touchAnimationHidden && !this.touchAnimationAlreadyShown;
            }
        },
    },
    created() {
        if (!isKioskDevice) {
            this.touchAnimationAlreadyShown = localStorage.getItem(`touchAnimationShown`) === `true`;
            if (this.touchAnimationAlreadyShown) {
                this.touchAnimationHidden = true;
            }
        }
    },
    mounted() {
        this.container = this.$refs[`pano-360-container`];

        // Warning THREE is using Y for vertical axis and Z for depth

        this.scene = new THREE.Scene();
        this.scene.backgroundIntensity = 0.8;

        this.updateOffset();

        this.renderer = new THREE.WebGLRenderer({antialias: true, premultipliedAlpha: false});
        this.renderer.setPixelRatio(window.devicePixelRatio);

        this.container.appendChild(this.renderer.domElement);

        // Camera is facing z-axis instead of x axis
        this.camera = new THREE.PerspectiveCamera(80, 1, 0.1, 1000);
        this.loadArrow();
        this.loadTexture();
        this.scene.add(this.arrow);

        if (this.isPanoDebugVisible) {
            // direction, origin, length
            // Red X - East
            this.scene.add(new THREE.ArrowHelper(new THREE.Vector3(1, 0, 0), new THREE.Vector3(0, -0.3, 0), 1.0, 0xff0000));
            // Green Y - Up
            this.scene.add(new THREE.ArrowHelper(new THREE.Vector3(0, 1, 0), new THREE.Vector3(0, -0.3, 0), 1.0, 0x00ff00));
            // Blue Z - North
            this.scene.add(new THREE.ArrowHelper(new THREE.Vector3(0, 0, -1), new THREE.Vector3(0, -0.3, 0), 1.0, 0x0000ff));
            this.scene.add(new THREE.ArrowHelper(new THREE.Vector3(0, 0, -1), new THREE.Vector3(0, 0, 0), 1.0, 0x0000ff));
            this.scene.add(new THREE.ArrowHelper(new THREE.Vector3(0, 0, -1), new THREE.Vector3(0, 0.3, 0), 1.0, 0x0000ff));
        }

        this.scene.add(this.camera);
        this.scene.fog = new THREE.Fog(0x5459ff, 0, 1.5);

        // Requires camera and controls
        this.updateContainerSize();
        this.setCameraAngle(this.fromAngle);
        this.render();

        /* Init touch events on arrow */
        // this.raycaster = new THREE.Raycaster();
        // this.mouse = new THREE.Vector2();
        // this.renderer.domElement.addEventListener(`click`, this.onDocumentMouseClick, false);

        requestAnimationFrame(() => {
            this.animateCameraToAngle(this.normalizedArrowAngle);
        });
    },
    methods: {
        roundFloat(value) {
            return Math.round(value * 100) / 100;
        },
        updateContainerSize() {
            if (this.fullscreen) {
                this.width = this.window.innerWidth;
                this.height = this.window.innerHeight - 120; // 120 for controls
            } else {
                this.width = this.container.offsetWidth;
                this.height = this.container.offsetHeight;
            }
            this.resize(this.width, this.height);
        },
        render() {
            this.renderer.render(this.scene, this.camera);
            // this.updateAngleFromCamera();
            requestAnimationFrame(this.render);
        },
        setCameraAngle(angle) {
            this.cameraAngle = angle;
            const cameraAngleRad = THREE.MathUtils.degToRad(this.cameraAngle);
            // camera rotation axis is counterclockwise
            this.camera.rotation.y = -cameraAngleRad;

            this.camera.position.x = this.orbitRadius * Math.sin(-cameraAngleRad);
            this.camera.position.z = this.orbitRadius * Math.cos(-cameraAngleRad);
            // console.log(`Update Camera angle ${angle}`);
        },
        updateAngleFromCamera() {
            // debug only
            const rotation = new THREE.Euler().setFromQuaternion(this.camera.quaternion);
            this.cameraRotation.x = THREE.MathUtils.radToDeg(rotation.x);
            this.cameraRotation.y = THREE.MathUtils.radToDeg(rotation.y);
            this.cameraRotation.z = THREE.MathUtils.radToDeg(rotation.z);

            const quaternion = this.camera.quaternion;
            // Calculate the yaw (Y axis rotation) directly from the quaternion, thanks Chat GPT
            const sinYCosp = 2 * (quaternion.w * quaternion.y + quaternion.z * quaternion.x);
            const cosYCosp = 1 - 2 * (quaternion.y * quaternion.y + quaternion.z * quaternion.z);
            let yaw = Math.atan2(sinYCosp, cosYCosp);
            yaw = THREE.MathUtils.radToDeg(yaw);

            // yaw += this.angleFix;

            if (yaw > 180) {
                yaw -= 360;
            } else if (yaw < -180) {
                yaw += 360;
            }
            // invert angle to make it clockwise
            this.cameraAngle = -yaw;
        },
        animateCameraToAngle(targetAngle, duration = 2500) {
            this.cameraAnimationCancelled = false;
            const startAngle = this.cameraAngle;

            let angleDiff = targetAngle - startAngle;
            if (angleDiff > 180) {
                angleDiff -= 360;
            } else if (angleDiff < -180) {
                angleDiff += 360;
            }

            const startTime = performance.now();

            // Todo, ideally move this to the render function since it's already updating every frame
            const animate = (time) => {
                if (this.cameraAnimationCancelled)
                    return;

                const elapsed = time - startTime;
                let progress = Math.min(elapsed / duration, 1);
                // Simple ease in / out
                // progress = -(Math.cos(Math.PI * progress) - 1) / 2
                progress = Math.sin((progress * Math.PI) / 2);
                const currentAngle = startAngle + (angleDiff * 0.75) * progress;

                this.setCameraAngle(currentAngle);

                if (progress < 1) {
                    requestAnimationFrame(animate);
                }
                // else {
                //     const nextAngle = (targetAngle === currentAngle) ? startAngle : targetAngle;
                //     this.animateCameraToAngle(nextAngle);
                // }
            };

            requestAnimationFrame(animate);

            window.addEventListener(`click`, () => {
                this.cameraAnimationCancelled = true;
            }, {once: true});

            window.addEventListener(`touchstart`, () => {
                this.cameraAnimationCancelled = true;
            }, {once: true});
        },
        updateArrowAngle() {
            // invert angle to make it clockwise
            if (this.isArrived) {
                this.arrow.rotation.set(THREE.MathUtils.degToRad(-90), 0, 0);
                this.arrow.position.y = 0.5;
            } else {
                this.arrow.position.y = -0.7;
                this.arrow.rotation.set(0, THREE.MathUtils.degToRad(-this.normalizedArrowAngle), 0);
            }
        },
        updateOffset() {
            // camera is facing z-axis, while background is facing x-axis so rotate by -90°
            const offsetAngle = parseInt(this.imgAngle || 0);
            this.scene.backgroundRotation.y = THREE.MathUtils.degToRad(offsetAngle + 90);
        },
        hideTouchAnimation() {
            this.touchAnimationHidden = true;

            if (!this.touchAnimationAlreadyShown) {
                localStorage.setItem(`touchAnimationShown`, `true`);
                this.touchAnimationAlreadyShown = true;
            }
        },
        onMouseDown(event) {
            this.isMouseDown = true;
            this.lastMouseX = event.clientX || event.pageX;
            this.lastMouseY = event.clientY || event.pageY;
            this.hideTouchAnimation();
        },
        onTouchMove(event) {
            this.onMouseMove(event.touches[0]);
            this.hideTouchAnimation();
        },
        onMouseMove(event) {
            if (!this.isMouseDown) return;

            const deltaX = (event.clientX || event.pageX) - this.lastMouseX;
            this.setCameraAngle(this.cameraAngle - deltaX * this.rotationSpeed);

            this.lastMouseX = (event.clientX || event.pageX);
            this.lastMouseY = (event.clientY || event.pageY);
        },
        onMouseUp() {
            this.isMouseDown = false;
        },
        //Ask permission to use device orientation (necessary for iOS 13+)
        async toggleARMode() {
            this.modeAR = !this.modeAR;

            if (!this.modeAR) {
                window.removeEventListener(`deviceorientationabsolute`, this.handleOrientation);
                window.removeEventListener(`deviceorientation`, this.handleOrientation);
            } else {
                if (typeof DeviceOrientationEvent !== `undefined` && typeof DeviceOrientationEvent.requestPermission === `function`) {
                    try {
                        const permissionState = await DeviceOrientationEvent.requestPermission();
                        if (permissionState === `granted`) {
                            this.addOrientationListener();
                        } else {
                            this.modeAR = false;
                            alert(`Permission was denied`);
                        }
                    } catch (error) {
                        alert(error);
                        this.modeAR = false;
                    }
                } else {
                    this.addOrientationListener();
                }
            }
        },
        addOrientationListener() {
            if (`ondeviceorientationabsolute` in window) {
                window.addEventListener(`deviceorientationabsolute`, this.handleOrientation);
            } else if (`ondeviceorientation` in window) {
                window.addEventListener(`deviceorientation`, this.handleOrientation);
            } else {
                console.error(`Device orientation not supported`);
            }
        },
        handleOrientation(event) {
            if (typeof event.webkitCompassHeading !== `undefined`) {
                // Use webkitCompassHeading (pointing North, only iOS)
                this.setCameraAngle(event.webkitCompassHeading + this.angleFix);
            } else {
                // Use alpha as fallback
                this.setCameraAngle(Math.abs(event.alpha - 360) + this.angleFix);
            }
            this.accelerometer.x = event.alpha;
            this.accelerometer.y = event.beta;
            this.accelerometer.z = event.gamma;
        },
        resize(width, height) {
            this.renderer.setSize(width, height);
            this.camera.aspect = width / height;
            this.camera.updateProjectionMatrix();
        },
        loadTexture() {
            this.isAvifSupported().then((supported) => {
                let url;

                if (supported) {
                    url = (this.isMobileLayoutOrPreview || !this.fullscreen) ? this.src.replace(`file.avif`, `file-mobile.avif`) : this.src;
                } else {
                    // check also webp support for fallback
                    const supportedWebp = document.createElement(`canvas`).toDataURL(`image/webp`).indexOf(`data:image/webp`) === 0;
                    if (!supportedWebp) {
                        alert(`Your browser does not support WebP format. Please use a modern browser.`);
                        return;
                    }

                    url = this.src.replace(`file.avif`, `thumbnail.webp`);
                }

                const texture = new THREE.TextureLoader().load(url);
                texture.wrapS = THREE.RepeatWrapping;
                texture.colorSpace = THREE.SRGBColorSpace;
                texture.mapping = THREE.EquirectangularReflectionMapping;
                texture.minFilter = texture.magFilter = THREE.LinearFilter;

                this.scene.background = texture;
            });


        },
        isAvifSupported() {
            return new Promise((resolve) => {
                const avif = new Image();
                avif.onload = () => resolve(true);
                avif.onerror = () => resolve(false);
                avif.src = `data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=`;
            });
        },
        convertHex(hex) {
            return Number(`0x${hex.substr(1)}`);
        },
        loadArrow() {
            this.arrow = new THREE.Group();
            this.arrow.scale.set(0.5, 0.5, 0.5);

            const arrowMaterial = new THREE.MeshPhongMaterial({
                color: this.convertHex(this.arrowColor),
                side: THREE.DoubleSide,
                emissive: 0x00c0ff,
                emissiveIntensity: 5,
                polygonOffset: true,
                polygonOffsetUnits: 1,
                polygonOffsetFactor: 0.5 /* positive value pushes polygon further away */
            });

            const shadowMaterial = new THREE.MeshBasicMaterial({
                color: this.convertHex(`#000000`),
                transparent: true,
                opacity: 0.4,
                fog: false,
                polygonOffset: true,
                polygonOffsetUnits: 1,
                polygonOffsetFactor: 0.5 /* positive value pushes polygon further away */
            });

            // Define the shape for the arrow head
            const arrowShape = new THREE.Shape();
            arrowShape.moveTo(-0.5, 0.3);
            arrowShape.lineTo(-0.18, 0.5);
            arrowShape.lineTo(-0.20, -0.5);
            arrowShape.lineTo(0.20, -0.5);
            arrowShape.lineTo(0.18, 0.5);
            arrowShape.lineTo(0.18, 0.5);
            arrowShape.lineTo(0.5, 0.3);
            arrowShape.lineTo(0.05, 1.45);
            arrowShape.lineTo(0, 1.48);
            arrowShape.lineTo(-0.05, 1.45);
            arrowShape.lineTo(-0.5, 0.3);

            // Create geometry from shape
            const arrowGeometry = new THREE.ShapeGeometry(arrowShape);
            arrowGeometry.rotateX(THREE.MathUtils.degToRad(-90));

            // Create Meshes from geometry (same geo, different mesh)
            const arrowMesh = new THREE.Mesh(arrowGeometry, arrowMaterial);
            const arrowShadow = new THREE.Mesh(arrowGeometry, shadowMaterial);
            arrowShadow.translateY(-0.12);
            this.arrow.add(arrowMesh);
            this.arrow.add(arrowShadow);

            // Outline to ensure we have enough contrast
            const outlineMaterial = new THREE.LineBasicMaterial({
                color: 0xffffff,
                linewidth: 1,
                fog: false
            });
            const edgesGeometry = new THREE.EdgesGeometry(arrowGeometry);
            const arrowOutline = new THREE.LineSegments(edgesGeometry, outlineMaterial);
            this.arrow.add(arrowOutline);
            this.updateArrowAngle();
        },
        onDocumentMouseClick(event) {
            const rect = this.renderer.domElement.getBoundingClientRect();
            this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
            this.mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

            this.raycaster.setFromCamera(this.mouse, this.camera);

            const intersects = this.raycaster.intersectObjects([this.arrow]);

            if (intersects.length > 0) {
                this.onArrowClick();
            }
        },
        onArrowClick() {
            console.log(`Arrow clicked!`);
        }
    },
    watch: {
        src() {
            this.loadTexture();
            this.updateOffset();
        },
        isArrived() {
            this.updateArrowAngle();
        },
        // fullscreen() {
        //     this.$nextTick(() => {
        //         this.updateContainerSize();
        //     });
        // },
        fromAngle(newAngle) {
            this.setCameraAngle(newAngle);
        },
        // for debug purpose only
        imgAngle() {
            this.updateOffset();
        },
        toAngle(newAngle, oldAngle) {
            if (newAngle !== oldAngle) {
                this.updateArrowAngle();
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.animateCameraToAngle(this.normalizedArrowAngle);
                    }, 100);
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.pano-360 {
    position: relative;
    width: var(--instruction-image-width);
    height: var(--instruction-image-height);
    padding: 0;
    border: none;

    .pano-360-container {
        width: inherit;
        height: inherit;
    }

    .btn.btn-toggle-ar,
    .btn.btn-toggle-fullscreen {
        position: absolute;
        right: 5px;
        //width: 140px;
        backdrop-filter: blur(5px) contrast(0.8);
        background-color: rgba(white, 0.8);

        &.active {
            background-color: var(--primary-color);
        }
    }


    .btn.btn-toggle-fullscreen {
        top: 5px;
    }

    .btn.btn-toggle-ar {
        bottom: 5px;
    }

    .debug {
        position: absolute;
        left: 0;
        top: 0;
        backdrop-filter: blur(5px) contrast(0.8);
        background-color: rgba(white, 0.8);
        padding: 3px 6px;
        border-bottom-right-radius: 8px;

        .badge {
            display: block;
        }
    }

    .controls {
        .btn {
            position: absolute;
            top: 0;
            width: 80px;
            height: 100%;
            border: none;

            .fas {
                font-size: 24px;
                color: white;
                text-shadow: 0 5px 5px rgba(black, 0.2);
            }

            &:hover {
                background-color: rgba(255, 255, 255, 0.25);
            }

            &.rotate-left {
                left: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            &.rotate-right {
                right: 0;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    &.fullscreen {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 70px;
        left: 0;
        width: 100%;
        height: calc(var(--app-height) - 120px); // 120px height for controls
        z-index: 1000;
    }

    .animated-layer {
        width: 100%;
        height: 100%;
        text-align: center;
        position: absolute;
        background-color: black;
        opacity: 20%;

    }

    .animated-finger {
        cursor: pointer;
        animation: flying-finger 4s 2;
        bottom: 50px;
        right: 50px;
        opacity: 0;
        transform: translate(-50%, -50%);
        padding: 10px;
        height: 150px;
        width: auto;
        position: absolute;
        pointer-events: none;
    }

    @keyframes flying-finger {
        0% {
            opacity: 0;
            transform: translatey(60px) translateX(0px);
        }
        30% {
            opacity: 1;
            transform: translatey(40px) translateX(0px);
        }
        60% {
            opacity: 1;
            transform: translatey(40px) translateX(-40px);
        }
        100% {
            opacity: 0;
            transform: translatey(60px) translateX(-40px);
        }
    }
}


</style>
