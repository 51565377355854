<template>
    <website-page class="cms-page-map" :class="{ 'assistant-visible': navPath, 'nav-mode': navMode && !navPath }" container-class="h-100" page-class="h-100"
                  v-style="website.body.page.style">
        <preview-responsive-container>
            <cms-block-builder :blocks="cmsPage ? cmsPage.blocks : []" no-footer>
                <map-container v-if="map && floorSelected" :map="map" :floor="floorSelected" :camera="camera" @reset-camera="resetCamera(true)"
                               show-controls :show-scale-legend="layers.legend" :show-floor-name="!isMobileLayoutOrPreview && !(wheelchairExtended && isWheelchairMode)">

                    <layer-map-item-links v-if="!navPath && isDebugVisible && debugSettings.links" :floor-links="floorLinks"/>
                    <layer-map-nav-path v-if="navPath && navMode" :key="floorSelected.id" :nav-path="navPath" :floor="floorSelected" :instruction="currentInstruction"/>
                    <layer-map-items @item-selected="selectMapItem" :item-selected="mapItemSelected" :floor="floorSelected" :floor-items="floorItems" :nav-path="navPath"
                                     :layers="layers"/>

                    <map-location-marker v-if="currentLocation" variant="primary" :item="currentLocation" :floor="floorSelected" outer-radius label="You are here|Vous êtes ici"/>
                    <map-location-marker v-else-if="startPoint" variant="primary" :item="startPoint" :floor="floorSelected" outer-radius label="Start|Départ"/>
                    <map-location-marker v-if="endPoint" variant="primary" :item="endPoint" :floor="floorSelected" outer-radius label="Destination"/>

                    <map-location-marker v-if="currentInstruction" :item="currentInstruction.currentItem" shape="arrow" :angle="currentInstruction.toAngle" :floor="floorSelected"/>

                    <template #controls>
                        <map-floor-selector :map="map" :floor-selected="floorSelected" @selected="selectFloor" :nav-path="navPath"/>
                    </template>
                </map-container>

                <map-cms-item-popup v-if="mapItemSelected && !navMode && !currentLocation" :map-item="mapItemSelected" :cms-item="mapItemSelected.cmsItem"
                                    @create-route="switchToNavMode" @close="mapItemSelected = null"/>

                <div class="assistant-placeholder" v-if="isDesktopDevice">
                    <div>
                        <div class="mb-3">
                            <i class="fa-route fa-3x" :class="fontAwesomePrefix" style="opacity: 0.2"/>
                        </div>
                        <div class="hint text-muted" v-tr>
                            <template v-if="!startPoint && !endPoint">{{ tr('Where would you like to go?|Où souhaitez-vous aller ?') }}</template>
                            <template v-else-if="!startPoint">{{ tr('Please select where you want to start|Merci de choisir votre point de départ') }}</template>
                            <template v-else-if="!endPoint">{{ tr('Please select where you want to go|Merci de choisir où vous souhaitez aller') }}</template>
                        </div>
                    </div>
                </div>

                <map-trajectory-search v-if="!isKioskDevice && (isDesktopDevice || (navMode && !navPath))" @hide="navMode = false" @updated="navPointUpdated" @clear-current-location="clearCurrentLocation"
                                       :map="map" :pathfinder="pathfinder" :current-location="currentLocation" :start-point.sync="startPoint" :end-point.sync="endPoint"/>
                <map-assistant v-if="navPath && navMode" :nav-path="navPath" @close="leaveNavPath" @terminated="terminateNavPath" @set-instruction="selectInstruction"
                               :wheelchair-extended.sync="wheelchairExtended" :class="{'wheelchair-extended': wheelchairExtended}"/>

                <div class="map-overlay" v-if="isWheelchairMode && wheelchairExtended" @click="wheelchairExtended = false">
                </div>

                <div class="directory" v-if="isKioskDevice && !navPath" :class="{'wheelchair-extended': wheelchairExtended}">
                    <div class="divider py-2 text-white" :class="isWheelchairMode ? 'bg-primary' : 'bg-dark'" v-touch:swipe="directorySwiped">
                        <b-btn class="float-right text-dark mr-3 mt-1" variant="white" v-if="isWheelchairMode" @click="wheelchairExtended = !wheelchairExtended">
                            <template v-if="!wheelchairExtended">
                                <span v-tr>Show|Afficher</span>
                                <i class="fa-chevron-up ml-1" :class="fontAwesomePrefix"/>
                            </template>
                            <template v-else>
                                <span v-tr>Hide|Masquer</span>
                                <i class="fa-chevron-down ml-1" :class="fontAwesomePrefix"/>
                            </template>
                        </b-btn>
                        <h2 v-tr>On this floor|À cet étage</h2>
                    </div>
                    <template v-if="!isWheelchairMode || wheelchairExtended">
                        <div class="this-floor">
                            <div class="floor-items">
                                <div class="floor-item" v-for="item in floorCmsItems" :key="`directory-${item.id}`" @click="selectMapItem(item.mapItem)">
                                    <i class="mr-1" :class="[fontAwesomePrefix, item.glyph || item.category?.icon || item.category?.glyph]"/>
                                    {{ item.getName()|tr }}
                                </div>
                            </div>
                        </div>
                        <div class="services-container">
                            <h2 class="divider py-2 bg-dark text-white" v-tr>Services</h2>
                            <div class="services my-4">
                                <div class="service" @click="selectService(`information`)">
                                    <div class="icon-container">
                                        <i class="fas fa-info fa-2x"/>
                                    </div>
                                    <div class="name h4 f-700" v-tr>Main Reception|Réception principale</div>
                                </div>
                                <div class="service" @click="selectMapItemById(`jqh03i`)">
                                    <div class="icon-container">
                                        <i class="fas fa-mug-hot fa-2x"/>
                                    </div>
                                    <div class="name h4 f-700" v-tr>Coffee Shop|Café</div>
                                </div>
                                <div class="service" @click="selectMapItemById(`mup5qc`)">
                                    <div class="icon-container">
                                        <i class="fas fa-utensils fa-2x"/>
                                    </div>
                                    <div class="name h4 f-700" v-tr>Cafeteria|Cafétéria</div>
                                </div>
                                <div class="service" @click="selectService(`atm`)">
                                    <div class="icon-container">
                                        <b-img src="https://eyeindevstorage.blob.core.windows.net/eye-intelligence/cms/library/hopitallakeshore/66d8bb8dcbfc57ed744a00d0/file.webp" style="filter: invert(1)"/>
                                    </div>
                                    <div class="name h4 f-700" v-tr>ATM|GAB</div>
                                </div>
                                <div class="service" @click="selectService(`parking-machine`)">
                                    <div class="icon-container">
                                        <b-img src="https://eyeindevstorage.blob.core.windows.net/eye-intelligence/cms/library/hopitallakeshore/66a444585ec9314775a0e338/file.svg"/>
                                    </div>
                                    <div class="name h4 f-700" style="font-size: 13pt" v-tr>Pay Parking|Guichet stationnement</div>
                                </div>
                                <div class="service" @click="selectService(`toilet`)">
                                    <div class="icon-container">
                                        <i class="fas fa-restroom-simple fa-2x"/>
                                    </div>
                                    <div class="name h4 f-700" v-tr>Toilet|Toilettes</div>
                                </div>
                            </div>
                            <img v-if="!isWheelchairMode"
                                 src="https://eyeindevstorage.blob.core.windows.net/eye-intelligence/cms/library/hopitallakeshore/66a445685ec9314775a0e33d/file.webp" width="100%"
                                 height="257"/>
                        </div>
                    </template>
                </div>
            </cms-block-builder>
        </preview-responsive-container>

        <b-modal v-model="floorModal.visible" size="lg" modal-class="change-floor-overlay" body-class="text-center" hide-footer hide-header>
            <div class="change-floor-content">
                <h3 class="mt-4 text-uppercase" v-tr>
                    Floor Change|Changement d'étage
                </h3>
                <div style="position: relative;">
                    <b-img class="elevator-image" @click="floorModal.visible = false" :src="require(`@/assets/icons/elevator-preview.webp`)"/>
                    <div class="floor-direction large" :class="`direction-${floorModal.direction}`">
                        <i class="fas" :class="floorModal.direction === 'up' ? 'fa-up-long' : 'fa-down-long'"/>
                    </div>
                </div>
                <h2 class="title mb-3">
                    <template v-if="floorModal.direction === 'up'">{{ tr('Go up to|Montez au') }}</template>
                    <template v-else-if="floorModal.direction === 'down'">{{ tr('Go down to|Descendez au') }}</template>
                    {{ floorModal.floorName }}
                </h2>
            </div>
        </b-modal>
    </website-page>
</template>

<script>
import CmsBlockBuilder from "@/components/editor/rendering/blocks/cms-block-builder.vue";
import LayerMapItemLinks from "@/components/maps/layers/layer-map-items-links.vue";
import LayerMapItems from "@/components/maps/layers/layer-map-items.vue";
import MapCmsItemPopup from "@/components/maps/layers/map-items/map-cms-item-popup.vue";
import MapTrajectorySearch from "@/components/maps/layers/map-items/map-trajectory-search.vue";
import MapContainer from "@/components/maps/map-container.vue";
import MapFloorSelector from "@/components/maps/map-floor-selector.vue";
import Network from "@/../vue-components/helpers/Network.js";
import MapMixin from "@/helpers/maps/map-mixin.js";
import Pathfinder from "@/helpers/maps/Pathfinder.js";
import {swal} from "vue-swal";
import SvgCamera from "../../vue-components/helpers/SvgCamera.js";
import PreviewResponsiveContainer from "@/layout/preview-responsive-container.vue";
import LayerMapNavPath from "../components/maps/layers/layer-map-nav-path.vue";
import MapLocationMarker from "../components/maps/layers/map-items/map-location-marker.vue";
import MapAssistant from "../components/maps/map-assistant.vue";
import NavPath from "../helpers/maps/NavPath.js";
import SessionTracker from "../../vue-components/helpers/SessionTracker.js";
import CmsNavLog from "../../common/db/models/cms/CmsNavLog.js";

// cms-page-map #hot-reload-fix
export default {
    name: `cms-page-map`,
    mixins: [MapMixin],
    components: {
        MapTrajectorySearch,
        MapCmsItemPopup,
        CmsBlockBuilder, PreviewResponsiveContainer, MapAssistant, LayerMapNavPath, MapLocationMarker, MapFloorSelector, LayerMapItems, LayerMapItemLinks, MapContainer
    },
    data() {
        return {
            currentLocation: null, // for kiosk only
            mapItemSelected: null,
            startPoint: null,
            endPoint: null,
            camera: null,
            currentInstruction: null,
            floorSelected: null,
            pathfinder: null,
            navPath: null,
            floorPath: null,
            navMode: false,
            floorModal: {
                visible: false,
                direction: `up`,
                floorName: null
            },
            wheelchairExtended: false,
            map: null,
            animationTimer: null,
            layers: {
                network: true,
                security: true,
                display: false,
                indoor: true,
                location: true,
                navigation: true,
                emergency: true,
                cabling: true,
                services: true,
                zone: true,
                goods: true,
                labels: false,
                legend: false,
                guides: false
            },
            cmsNavLog: null
        }
    },
    computed: {
        floorItems() {
            if (!this.map || !this.map.items || !this.floorSelected) {
                return null;
            }
            let items = this.map.items.filter(item => item.isOnFloor(this.floorSelected.id));
            items.sort((a, b) => {
                return a.zIndex - b.zIndex;
            })
            return items;
        },
        floorCmsItems() {
            if (!this.floorItems) {
                return null;
            }
            let items = [];
            for (let mapItem of this.floorItems) {
                if (mapItem.cmsItem && (mapItem.cmsItem.type === `department` || mapItem.cmsItem.type === `store`)
                        && items.indexOf(mapItem.cmsItem) === -1) {
                    items.push(mapItem.cmsItem);
                }
            }
            items.sort((a, b) => this.tr(a.getName()).localeCompare(this.tr(b.getName())));
            return items;
        },
        floorLinks() {
            if (!this.map.items || !this.floorSelected) {
                return null;
            }
            return this.map.links.filter(link => link.floorId === this.floorSelected.id);
        },
        startPointComputed() {
            if (this.currentLocation) {
                return this.currentLocation;
            } else {
                return this.startPoint;
            }
        }
    },
    activated() {
        if (!this.cmsPage) {
            this.init();
        } else {
            this.initMap();
        }
    },
    mounted() {
        if (!this.cmsPage) {
            this.init();
        } else {
            this.initMap();
        }
    },
    methods: {
        init() {
            this.loading = true;
            this.showLoading();
            Network.get(`/api/page/${this.$route.params.pageId}`)
                .then(resp => {
                    this.$store.commit(`setPage`, {page: resp.data, route: this.$route});
                    this.initMap();
                    this.hideLoading();
                    this.loading = false;
                })
                .catch(_ => {
                    this.loading = false;
                })
        },
        initMap() {
            this.map = this.currentMap;

            this.$store.commit(`mapEditor/setMap`, this.map);

            this.pathfinder = new Pathfinder(this.map);

            let floor = null;

            // debug
            if (this.isKioskDevice) {
                if (this.$store.state.playerId) {
                    this.currentLocation = this.map.items.find(i => i.integrations.signage.playerId === this.$store.state.playerId);
                } else {
                    this.currentLocation = this.map.getItemById(`87tbf0`);
                }
            } else if (localStorage.getItem(`qrCode`)) {
                this.currentLocation = this.map.getItemByQRCode(localStorage.getItem(`qrCode`));
            } else {
                this.currentLocation = null;
            }

            if (this.currentLocation) {
                floor = this.map.getFloorById(this.currentLocation.floorId);
            }

            const pointSelected = this.$route.query.point;
            if (pointSelected) {
                this.selectMapItem(this.findClosestItemByPermalink(pointSelected));
                if (this.mapItemSelected) {
                    floor = this.map.getFloorById(this.mapItemSelected.floorId);
                }
            }

            const start = this.$route.query.start || this.$route.query.depart;
            if (start) {
                this.navMode = true;
                this.selectMapItem(this.findClosestItemByPermalink(start), `start`);
                if (this.startPoint) {
                    floor = this.map.getFloorById(this.startPoint.floorId);
                }
            }
            const end = this.$route.query.end || this.$route.query.arrivee;
            if (end) {
                this.selectMapItem(this.findClosestItemByPermalink(end), `end`);
                if (this.endPoint && !this.startPoint) {
                    floor = this.map.getFloorById(this.endPoint.floorId);
                }
            }

            const itemType = this.$route.query.closest || this.$route.query.autour;
            if (itemType) {
                this.findClosestItemByType(itemType);
            }

            // the nav path select floor automatically
            if (!this.navPath) {
                if (!floor) {
                    floor = this.map.getMainFloor();
                }
                this.selectFloor(floor);
            }

            this.camera = new SvgCamera(this.floorSelected.realWidth, this.floorSelected.realHeight, this.getDefaultZoom());
            if (this.isMobileLayout) {
                this.camera.padding = 10;
                this.camera.minZoom = 100;
            }
            this.resetCamera(false);

            setTimeout(() => {
                this.fitCameraToNavPath();
            }, 500);
        },
        resetCamera(animated = true) {
            // not working yet since camera center is not updated by viewbox

            // default values for compatibility with Lakeshore, to remove soon
            let center = {x: this.floorSelected.realWidth / 2, y: this.floorSelected.realHeight / 2};
            if (!this.map.display.center) {
                center.x = 130;
                center.yy = 100;
            } else if (this.map.display.center.customize) {
                center.x = this.map.display.center.x;
                center.y = this.map.display.center.y;
            }

            if (animated) {
                this.camera.moveTo(center.x, center.y);
            } else {
                this.camera.center.x = center.x;
                this.camera.center.y = center.y;
            }

            this.camera.zoomPercent = this.getDefaultZoom();
        },
        getDefaultZoom() {
            if (this.isKioskDevice) {
                return 200;
            } else if (this.isMobileLayout) {
                return 300;
            } else {
                return 150;
            }
        },
        selectFloor(floor) {
            if (this.animationTimer) {
                clearTimeout(this.animationTimer);
            }
            this.$store.commit(`mapEditor/setFloor`, floor);
            this.floorSelected = floor;
            if (floor && this.navPath) {
                this.floorPath = this.navPath.getFloorPath(floor.id);
                this.fitCameraToNavPath();
            } else {
                this.floorPath = null;
            }
        },
        switchToNavMode(target = `go-to`) {
            if (this.mapItemSelected) {
                if (target === `start-from`) {
                    this.startPoint = this.mapItemSelected;
                } else {
                    this.endPoint = this.mapItemSelected;
                }
                this.mapItemSelected = null;
                this.navMode = true;
            }
        },
        leaveNavPath() {
            if (this.animationTimer) {
                clearTimeout(this.animationTimer);
            }
            if (this.isKioskDevice) {
                this.endPoint = null;
                this.navMode = false;
            } else {
                this.startPoint = null;
                this.endPoint = null;
                if (this.isMobileLayout) {
                    this.navMode = false;
                }
            }
            this.navPath = null;
            window.history.pushState({}, ``, `${this.$route.path}`);
        },
        navPointUpdated() {
            if (this.startPoint && this.endPoint) {
                this.findClosestPath(this.startPoint, this.endPoint);
            } else {
                if (this.startPoint || this.endPoint) {
                    this.navMode = true;
                }
                if (this.navPath) {
                    this.navPath = null;
                    this.currentInstruction = null;
                }
            }
        },
        clearCurrentLocation() {
            this.currentLocation = null;
        },
        /**
         * Select the map item and switch automatically to navMode if necessary
         * @param item {MapItem}
         * @param type {String} auto|start|end
         */
        selectMapItem(item, type = `auto`) {
            // prevent map click selection when in nav path to avoid creating unwanted paths
            if (this.navPath && item) {
                // switch floor when clicking on relevant items
                if ((item.type === `elevator` || item.type === `stairs`) && this.navPath.startFloor.id !== this.navPath.endFloor.id) {
                    if (this.floorSelected.id === this.navPath.startFloor.id) {
                        this.selectFloor(this.navPath.endFloor);
                    } else {
                        this.selectFloor(this.navPath.startFloor);
                    }
                }

                return;
            }

            if (!item) {
                return this.unselectMapItem();
            }
            if (this.currentLocation) {
                this.endPoint = item;
                this.findClosestPath(this.currentLocation, this.endPoint);
                this.navMode = true;
            } else {
                if (this.navMode || type !== `auto`) {
                    if (!this.startPoint && type !== `end`) {
                        if (this.endPoint === item) {
                            swal({
                                title: this.tr(`Are you sure?|Drôle de chemin`),
                                text: this.tr(`Please select a different destination than your starting point|Merci de choisir un point différent de votre destination`),
                                icon: `warning`
                            });
                            return;
                        }
                        this.startPoint = item;

                        // check if there is a closest map item
                        if (this.startPoint && this.endPoint?.cmsItem?.hasMultipleMapItems()) {
                            this.endPoint = this.findClosestItemByCmsItemId(this.startPoint, this.endPoint.cmsItem.id);
                        }
                    } else {
                        if (this.startPoint === item) {
                            swal({
                                title: this.tr(`Are you sure?|Drôle de chemin`),
                                text: this.tr(`Please select a different destination than your starting point|Merci de choisir un point différent de votre destination`),
                                icon: `warning`
                            });
                            return;
                        }
                        this.endPoint = item;

                        // check if there is a closest map item
                        if (this.endPoint && this.startPoint?.cmsItem?.hasMultipleMapItems()) {
                            this.startPoint = this.findClosestItemByCmsItemId(this.endPoint, this.startPoint.cmsItem.id);
                        }
                    }
                    if (this.startPoint && this.endPoint) {
                        this.findClosestPath(this.startPoint, this.endPoint);
                    }
                    this.navMode = true;
                } else {
                    this.mapItemSelected = item;
                }
            }
        },
        findClosestItemByPermalink(permalink) {
            let items = this.map.getItemsByPermalink(permalink);
            return this.pathfinder.findClosestItem(this.startPointComputed, items);
        },
        findClosestItemByCmsItemId(startPoint, cmsItemId) {
            let items = this.map.getItemsByCmsItemId(cmsItemId);
            return this.pathfinder.findClosestItem(startPoint, items);
        },
        findClosestItemByType(itemType) {
            if (!this.startPointComputed) {
                swal({
                    title: this.tr(`Where are you located?|Où êtes-vous situés ?`),
                    text: this.tr(`Please select where you want to start from first|Merci de choisir d'abord d'où vous souhaitez partir`),
                    icon: `warning`
                })
                return;
            }
            const items = this.map.getItemsByType(itemType);
            let closestMapPoint = this.pathfinder.findClosestItem(this.startPointComputed, items);
            if (!closestMapPoint) {
                swal({
                    title: this.tr(`Could not find path|Chemin non trouvé`),
                    icon: `warning`
                })
                this.navPath = null;
            } else {
                this.selectMapItem(closestMapPoint);
            }
        },
        findClosestPath(start, end) {
            let closestPath = this.pathfinder.findClosestPath(start, end);
            if (!closestPath) {
                swal({
                    title: this.tr(`Could not find path|Chemin non trouvé`),
                    icon: `warning`
                })
                this.navPath = null;
            } else {
                this.navPath = new NavPath(closestPath);
                this.navMode = true;
            }
            if (this.navPath) {
                if (!this.isKioskDevice) {
                    window.history.pushState({}, ``, `${this.$route.path}?${this.tr(`start|depart`)}=${start.getPermalink()}&${this.tr(`end|arrivee`)}=${end.getPermalink()}`);
                } else {
                    window.history.pushState({}, ``, `${this.$route.path}?${this.tr(`end|arrivee`)}=${end.getPermalink()}`);
                }

                const startPoint = {
                    id: this.startPointComputed.id,
                    name: this.startPointComputed.name,
                    type: this.startPointComputed.type,
                    floor: {
                        id: this.navPath.startFloor.id,
                        name: this.navPath.startFloor.name
                    }
                }
                if (this.startPointComputed.cmsItem) {
                    startPoint.cmsItem = {
                        id: this.startPointComputed.cmsItem.id,
                        name: this.startPointComputed.cmsItem.name,
                        type: this.startPointComputed.cmsItem.type
                    }
                }

                const endPoint = {
                    id: this.endPoint.id,
                    name: this.endPoint.name,
                    type: this.endPoint.type,
                    floor: {
                        id: this.navPath.endFloor.id,
                        name: this.navPath.endFloor.name
                    }
                }
                if (this.endPoint.cmsItem) {
                    endPoint.cmsItem = {
                        id: this.endPoint.cmsItem.id,
                        name: this.endPoint.cmsItem.name,
                        type: this.endPoint.cmsItem.type
                    }
                }

                if (SessionTracker) {
                    SessionTracker.addCustomAction(`navPath`, {startPoint, endPoint});
                }

                this.cmsNavLog = new CmsNavLog({
                    dealerid: this.dealerid,
                    mapId: this.map.id,
                    startPoint,
                    endPoint
                });
                if (this.isKioskDevice) {
                    this.cmsNavLog.playerId = localStorage.getItem(`playerId`);
                } else if (localStorage.getItem(`qrCode`)) {
                    this.cmsNavLog.qrCode = localStorage.getItem(`qrCode`);
                }

                this.cmsNavLog.started++;

                this.cmsNavLog.save()
                    .catch(e => console.error(e));
            } else {
                window.history.pushState({}, ``, `${this.$route.path}`);
            }
            this.nextFloorPath();
        },
        getNextFloorPath() {
            if (!this.navPath.floorPaths.length) {
                return null;
            }

            if (this.floorPath === null) {
                return this.navPath.floorPaths[0];
            } else {
                const index = this.navPath.floorPaths.indexOf(this.floorPath);
                if (this.navPath.floorPaths.length > index + 1) {
                    return this.navPath.floorPaths[index + 1];
                }
            }
            return null;
        },
        nextFloorPath() {
            const floorPath = this.getNextFloorPath();
            if (!floorPath) {
                return;
            }

            this.selectFloor(floorPath.floor);
            const nextFloorPath = this.getNextFloorPath();
            if (nextFloorPath) {
                let verb = `Go to|Allez au`;
                switch (this.navPath.floorDirection) {
                    case `up`:
                        verb = `Go up to the|Montez au`
                        break;
                    case `down`:
                        verb = `Go down to the|Descendez au`
                        break;
                }
                if (this.isMobileLayoutOrPreview) {
                    return;
                }
                this.animationTimer = setTimeout(() => {
                    if ((this.currentInstruction && !this.isKioskDevice) ||
                        (this.currentInstruction?.index > 0 && this.isKioskDevice))
                        return;

                    this.floorModal.visible = true;
                    this.floorModal.direction = this.navPath.floorDirection;
                    this.floorModal.floorName = this.tr(nextFloorPath.floor.getName());

                    setTimeout(() => {
                        this.floorModal.visible = false;
                        this.nextFloorPath();
                    }, 4000);
                }, (this.floorPath.duration + 2) * 1000);
            }
        },
        fitCameraToNavPath() {
            if (this.floorPath && this.camera) {
                this.camera.smoothlyContainPoints(this.floorPath.items, 1500);
            }
        },
        terminateNavPath() {
            localStorage.removeItem(`qr-code`);

            this.cmsNavLog.finished++;

            this.cmsNavLog.save()
                .catch(e => console.error(e));

            this.unselectMapItem();
        },
        unselectMapItem() {
            this.navPath = null;
            this.currentInstruction = null;
            this.startPoint = null;
            this.endPoint = null;
            this.navMode = false;
            window.history.pushState({}, ``, `${this.$route.path}`);

            if (this.animationTimer) {
                clearTimeout(this.animationTimer);
            }
        },
        selectInstruction(mapInstruction) {
            this.currentInstruction = mapInstruction;
            if (this.currentInstruction && this.currentInstruction.fromFloor !== this.floorSelected) {
                this.selectFloor(this.currentInstruction.fromFloor);
            }
        },
        selectMapItemById(itemId) {
            this.selectMapItem(this.map.getItemById(itemId));
        },
        directorySwiped(direction) {
            if (!this.isWheelchairMode)
                return;

            if (direction === `top`) {
                this.wheelchairExtended = true;
            } else if (direction === `bottom`) {
                this.wheelchairExtended = false;
            }
        },
        selectService(type) {
            this.findClosestItemByType(type);
        }
    }
}
</script>

<style lang="scss" scoped>
.cms-page-map {
    --map-assistant-width: 400px;
    --map-container-left-padding: 400px;
    --map-top-padding: 0px;
}

.layout-mobile {
    .cms-page-map {
        --map-assistant-width: 100vw;
        --map-container-left-padding: 0px;
    }
}

.layout-kiosk {
    .cms-page-map {
        //--kiosk-map-height: 1600px;
        --kiosk-map-height: 850px;
        --map-container-left-padding: 0px;

        //&.assistant-visible {
        //    --kiosk-map-height: 750px;
        //}
    }
}

.accessibility-wheelchair {
    .cms-page-map {
        --wheelchair-reduced-height: 65px;
        --kiosk-map-height: calc(100vh - (var(--wheelchair-top-margin) + var(--wheelchair-reduced-height)));
    }
}

.assistant-placeholder {
    position: fixed;
    width: var(--map-assistant-width);
    top: var(--page-padding-top);
    height: calc(var(--app-height) - var(--menu-height));
    left: var(--page-padding-left);
    background: #fafafa;
    border-right: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
}

.directory {
    background: #eee;
    color: #333;
    height: calc(100vh - var(--kiosk-map-height)) !important;
    display: flex;
    flex-direction: column;

    .this-floor {
        padding: 30px;
        flex: 2;
        overflow: hidden auto;

        .floor-items {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 5px 30px;


            .floor-item {
                background: #fff;
                text-align: left;
                padding: 10px 20px;
                border-radius: var(--border-radius);
                //border-bottom: 1px solid #ccc;

                & + .floor-item {

                }
            }
        }
    }

    .services-container {
        background: #eee;
        color: #333;
        flex: 1;
        min-height: 509px;
        max-height: 509px;

        .services {
            display: flex;
            justify-items: stretch;
        }

        .service {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 190px;

            .icon-container {
                width: 70px;
                height: 70px;
                border-radius: 10px;
                background: #095797;
                color: white;
                //border: 3px solid white;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-bottom: 10px;
            }

            i {
                font-size: 40px;
            }

            img {
                width: 50px;
            }

            .name {
                text-transform: uppercase;
                letter-spacing: 1px;
            }
        }
    }
}

.accessibility-wheelchair {
    .map-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: calc(100% - var(--menu-width));
        background: rgba(black, 0.4);
    }

    .directory {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        height: var(--wheelchair-reduced-height) !important;
        overflow: hidden;
        bottom: 0;
        position: absolute;
        left: calc(var(--map-container-left-padding) + 10px);
        width: calc(100% - var(--menu-width) - 20px);

        .divider {
            border-radius: inherit;
            height: var(--wheelchair-reduced-height);
        }

        &.wheelchair-extended {
            top: 70px;
            height: auto !important;
            z-index: 10;
            box-shadow: 0 -5px 15px rgba(black, 0.3);
        }
    }

    .services-container {
        background: #eee;
        color: #333;
        flex: 1;
        min-height: auto;
        max-height: initial;
    }
}

.change-floor-content {
    --elevator-img-size: 600px;
    --elevator-icon-size: 256px;

    .elevator-image {
        width: var(--elevator-img-size);
        max-width: 100%;
    }

    .floor-direction {
        position: absolute;
        left: calc(50% - var(--elevator-icon-size) / 2);
        top: calc(var(--elevator-img-size) / 2 - var(--elevator-icon-size) / 2);
        background: var(--accent-color);
        border-radius: 50%;
        width: var(--elevator-icon-size);
        height: var(--elevator-icon-size);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: calc(var(--elevator-icon-size) / 2.1);
        color: white;

        &.direction-up {
            animation: floor-up 2s infinite;
        }

        &.direction-down {
            animation: floor-down 2s infinite;
        }
    }

    .title {
        font-size: 26pt;
        font-weight: 500;
    }

    @keyframes floor-up {
        0% {
            opacity: 0;
            transform: translatey(25px);
        }
        10% {
            opacity: 0.8;
            transform: translatey(0px);
        }
        50% {
            opacity: 0.8;
            transform: translatey(0px);
        }
        100% {
            opacity: 0;
            transform: translatey(-100px);
        }
    }

    @keyframes floor-down {
        0% {
            opacity: 0;
            transform: translatey(-25px);
        }
        25% {
            opacity: 0.8;
            transform: translatey(0px);
        }
        50% {
            opacity: 0.8;
            transform: translatey(0px);
        }
        100% {
            opacity: 0;
            transform: translatey(100px);
        }
    }
}

.layout-mobile {
    .change-floor-content {
        --elevator-img-size: 300px;
        --elevator-icon-size: 150px;
    }

    .cms-page-map.nav-mode {
        --map-top-padding: 120px;
    }
}
</style>
