<template>
    <vue-draggable-resizable :x="xComputed" :y="yComputed" :z="zIndex" :draggable="draggable" :resizable="resizable" :parent="lockInParent" drag-handle=".panel-header"
                             class="e-panel animated fadeIn" :class="{ 'draggable': draggable, 'fadeIn': visible, 'fadeOut': !visible, 'minimized': minimized, 'maximized': maximized }"
                             :style="computedStyle">
        <div class="panel-header" v-if="!noHeader" :class="headerClass">
            <slot name="header">
                <h4 class="panel-title">{{title}}</h4>
            </slot>
            <div class="header-actions">
                <slot name="header-actions"></slot>

                <b-btn v-if="showMinimize" @click="minimized = !minimized" variant="light">
                    <i class="fas" :class="minimized ? 'fa-window-maximize' : 'fa-window-minimize'"/>
                </b-btn>

                <b-btn v-if="showMaximize" @click="maximized = !maximized" variant="light">
                    <i class="fas" :class="maximized ? 'fa-minimize' : 'fa-maximize'"/>
                </b-btn>

                <slot v-if="!noClose" name="close" v-bind="{hide}">
                    <b-btn variant="light" @click.prevent="hide" class="close"><i class="fas fa-close"/></b-btn>
                </slot>
            </div>
        </div>
        <div class="panel-body" :class="bodyClass">
            <slot></slot>
        </div>
        <div class="panel-footer" v-if="!noFooter" :class="footerClass">
            <slot name="footer"></slot>
        </div>
    </vue-draggable-resizable>
</template>

<script>
    import VueDraggableResizable from "vue-draggable-resizable"

    export default {
        name: `e-panel`,
        components: {
            VueDraggableResizable
        },
        props: {
            title: { type: String, default: `` },
            draggable: { type: Boolean, default: () => !isMobileLayout },
            resizable: { type: Boolean },
            headerClass: { type: String, default: `` },
            bodyClass: { type: String, default: `` },
            footerClass: { type: String, default: `` },
            lockInParent: { type: Boolean },
            minWidth: { type: [String, Number] },
            showMinimize: { type: Boolean },
            showMaximize: { type: Boolean },
            noHeader: { type: Boolean },
            noFooter: { type: Boolean },
            noClose: { type: Boolean },
            x: { type: [String, Number], default: window.innerWidth / 2 - 400 },
            y: { type: [String, Number], default: 100 },
            zIndex: { type: [String, Number], default: 1000 }
        },
        data() {
            return {
                visible: true,
                maximized: false,
                minimized: false
            }
        },
        computed: {
            xComputed: {
                get() {
                    return this.x;
                },
                set(value) {
                    return this.$emit(`update:x`, value);
                }
            },
            yComputed: {
                get() {
                    return this.y;
                },
                set(value) {
                    return this.$emit(`update:y`, value);
                }
            },
            computedStyle() {
                return {
                    'min-width': `${this.minWidth}px`
                }
            },
            left() {
                return window.innerWidth / 2 - 400;
            }
        },
        mounted() {
            if (this.isMobileLayout) {
                this.xComputed = 0;
                this.yComputed = 0;
            }
        },
        methods: {
            show() {
                this.visible = true;
                this.$emit(`shown`);
            },
            hide() {
                this.visible = true;
                this.$emit(`close`);
                this.$emit(`hidden`);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .e-panel {
        position: fixed !important;
        top: 0;
        left: 0;
        height: auto !important;
        margin: 5px;
        user-select: none;
        color: rgba(255, 255, 255, 0.9);
        border-radius: 5px;
        background: linear-gradient(230deg, #0c3749 0%, #1c2f3e 100%);
        box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, 0.25);

        .panel-body {
            border-radius: inherit;
            padding: 10px;
        }

        .panel-header {
            display: flex;
            width: 100%;
            padding: 10px 10px 10px 20px;
            border-bottom: none;
            background: white;
            color: #333;
            align-items: center;
            user-select: none;
            border-top-left-radius: inherit;
            border-top-right-radius: inherit;

            .panel-title {
                text-transform: initial;
                font-weight: 500;
                font-size: 14pt;
                letter-spacing: 1px;
                margin: 0;
            }

            ::v-deep h4 {
                margin-top: 0;
                margin-bottom: 0;
            }

            .close {
                width: 40px !important;
                height: 40px !important;
                opacity: 1;
            }

            .header-actions {
                position: relative;
                margin-left: auto;

                > .btn {
                    min-width: 40px;
                    height: 40px;

                    & + .btn {
                        margin-left: 5px;
                    }
                }
            }
        }

        &.draggable {
            .panel-header {
                cursor: move;
            }
        }

        .panel-footer {
            background: white;
            color: #333;
            display: flex;
            width: 100%;
            padding: 10px;
            justify-content: flex-end;
            border-top: none;
            align-items: center;
            border-bottom-left-radius: inherit;
            border-bottom-right-radius: inherit;

            ::v-deep .btn {
                padding: 10px 20px;

                &.btn-secondary {
                    background: #eee;
                    color: #333 !important;
                    border: 1px solid rgba(black, 0.15);
                    outline: none;

                    &:hover {
                        background: #ddd;
                    }

                    &:active {
                        background: #ccc;
                    }
                }
            }
        }

        &.minimized {
            position: fixed !important;
            left: auto !important;
            right: 15px !important;
            top: auto !important;
            bottom: 0 !important;
            min-width: initial !important;
            width: 400px !important;
            padding: 0 !important;
            margin: 0 !important;

            --minimized-height: 60px;

            height: var(--minimized-height);

            .panel-body {
                display: none;
            }
        }

        &.maximized {
            left: 0 !important;
            top: 0 !important;
            right: 0 !important;
            bottom: 0 !important;
            width: auto !important;
            height: auto !important;
            margin: 0 !important;
         }
    }

    @media(max-width: 576px) {
        .panel {
            left: 0 !important;
            top: 0 !important;
            right: 0 !important;
            bottom: 0 !important;
            width: auto !important;
            height: auto !important;
        }
    }
</style>
