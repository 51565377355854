import {tr} from "../../../vue-components/helpers/i18n.js";
import moment from "moment"

export default class MapHelper {
    static updateCoordinatesHistory(geolocationHistory) {
        let history = [];
        for (let data of geolocationHistory) {
            // do not insert data if speed is obviously too high or if doesn't contains coordinates
            if (!data.lat || !data.lng || data.sp > 150) {
                continue;
            }

            data.minute = MapHelper.dateToMinutes(data.ts);
            data.speed_kmph = Math.round(data.sp);
            data.speed_mph = MapHelper.kmphToMph(data.sp);
            data.status = `online`;

            history.push(data);
        }
        return history;
    }

    static getGeoData(equipment, history, currentMinute) {
        if (history && history.length > 0) {
            if (!currentMinute) {
                return history[history.length - 1];
            } else {
                let index = 0;
                for (let data of history) {
                    if (data.minute >= currentMinute) {
                        if (index === 0) {
                            return history[0];
                        }
                        return history[index - 1];
                    }
                    index++;
                }
                return history[history.length - 1];
            }
        }
        return null;
    }

    static getCoordinates(equipment, history, currentMinute) {
        let geoData = MapHelper.getGeoData(equipment, history, currentMinute);

        if (geoData && geoData.lat && geoData.lng) {
            return [geoData.lat, geoData.lng];
        }
        if (equipment.latitude && equipment.longitude) {
            return [equipment.latitude, equipment.longitude];
        }
        return null;
    }

    // Date helpers for timeline
    static dateToHours(datetime) {
        return moment(datetime).hours();
    }

    static dateToMinutes(datetime) {
        return moment(datetime).hours() * 60 + moment(datetime).minutes();
    }

    static formatMinutes(value) {
        if (isStringEmpty(value)) {
            return `-`;
        } else {
            return `${parseInt(value / 60)}h${fillIntegerWithZero(value % 60)}`;
        }
    }

    static formatSpeed(speed) {
        if (speed > 0) {
            return tr([this.kmphToMph(speed) + `mph`, Math.round(speed) + `km/h`]);
        } else {
            return speed;
        }
    }

    static formatDistance(meters) {
        if (meters > 0) {
            return tr([this.metersToFeet(meters) + `ft`, Math.round(meters) + `m`]);
        } else {
            return meters;
        }
    }

    static metersToFeet(altitude) {
        if (!altitude) {
            return altitude;
        }
        return Math.round(parseFloat(altitude) * 3.28);
    }

    static kmphToMph(speed) {
        if (!speed) {
            return speed;
        }
        return Math.round(parseFloat(speed) * 0.6213711922);
    }
}
