export default class SvgCamera {
    constructor(realWidth, realHeight, defaultZoom = 100) {
        this.realWidth = realWidth;
        this.realHeight = realHeight;

        this.center = {x: this.realWidth / 2, y: this.realHeight / 2};
        this.offset = {x: 0, y: 0};
        this.maxTranslationX = null;
        this.maxTranslationY = null;

        // safe margin (in SVG units for now)
        this.padding = 20;

        this.pxToSvgScaleRatio = 1;
        this.pxDensity = 1;

        // normalized value between 0-10
        this.zoomLevel = 5;

        this.zoomPercent = defaultZoom;// percentage, trigger map update
        this.minZoom = 50;
        this.maxZoom = 1000;
        this.zoomStep = 10;
        // controlled by e-interactive-svg
        this.syncViewBox = {minX: 0, minY: 0, width: realWidth, height: realHeight};
        this.viewBox = {minX: 0, minY: 0, width: realWidth, height: realHeight};
        this.animation = {
            enabled: false,
            duration: 0,
            progress: 0,
            lastFrame: null,
            move: {
                enabled: false,
                start: {x: 0, y: 0, zoom: 1},
                end: {x: 0, y: 0, zoom: 1}
            },
            viewBox: {
                enabled: false,
                start: {},
                end: {}
            },
            zoom: {
                enabled: false,
                start: 1,
                end: 1
            }
        }
    }

    // get viewBoxString() {
    //     return `${this.viewBox.minX} ${this.viewBox.minY} ${this.viewBox.width} ${this.viewBox.height}`
    // }

    get centerX() {
        return this.center.x + this.offset.x;
    }

    get centerY() {
        return this.center.y + this.offset.y;
    }

    pxToSVGScale(sizeInPx) {
        return sizeInPx * this.pxToSvgScaleRatio;
    }

    /**
     * Ensures X coord are within bounds (based on maxTranslation or realWidth)
     * @param x {number}
     * @returns {number}
     */
    clampX(x) {
        const maxTranslationX = this.maxTranslationX ? this.maxTranslationX : this.realWidth;
        let value = Math.min(x, maxTranslationX);
        value = Math.max(value, -maxTranslationX);
        return value;
    }

    /**
     * Ensures Y coord are within bounds (based on maxTranslation or realWidth)
     * @param y {number}
     * @returns {number}
     */
    clampY(y) {
        const maxTranslationY = this.maxTranslationY ? this.maxTranslationY : this.realHeight;
        let value = Math.min(y, maxTranslationY);
        value = Math.max(value, -maxTranslationY);
        return value;
    }

    /*
    Animations
     */
    /**
     * Simple Easing in and out to make animation smoother
     * @param x
     * @returns {number}
     */
    easeInOutSine(x) {
        return -(Math.cos(Math.PI * x) - 1) / 2;
    }

    resetAnimation() {
        this.animation.progress = 0;
        this.animation.move.enabled = false;
        this.animation.viewBox.enabled = false;
        this.animation.zoom.enabled = false;
    }

    startAnimation(duration = 500) {
        this.animation.duration = duration;
        this.animation.enabled = true;
        this.animation.lastFrame = null;
        this.animate();
    }

    smoothlyContainPoints(points, duration = 500) {
        if (points.length === 0) {
            throw new Error(`points array is empty`);
        } else if (points.length < 2) {
            throw new Error(`points array only containing one point, use centerView instead`);
        }

        let minX = points[0].x;
        let minY = points[0].y;
        let maxX = points[0].x;
        let maxY = points[0].y;
        for (let i = 1; i < points.length; i++) {
            minX = Math.min(points[i].x, minX);
            minY = Math.min(points[i].y, minY);
            maxX = Math.max(points[i].x, maxX);
            maxY = Math.max(points[i].y, maxY);
        }

        this.resetAnimation();

        this.animation.viewBox.enabled = true;
        // pxToSVGScale is using the current zoom, and not the one that should be targeted for the new viewbox
        const p = this.padding;
        // const p = this.pxToSVGScale(this.padding);
        this.animation.viewBox.start = {...this.syncViewBox};

        let width = maxX - minX + 2 * p;
        let height = maxY - minY + 2 * p;

        if (height > width) {
            width = height * this.syncViewBox.width / this.syncViewBox.height;
            minY = minY - p;
            minX = minX + (maxX - minX) / 2 - width / 2;
        } else {
            height = width * this.syncViewBox.height / this.syncViewBox.width;
            minX = minX - p;
            minY = minY + (maxY - minY) / 2 - height / 2;
        }
        this.animation.viewBox.end = {minX: minX, minY: minY, width: width, height: height};

        this.startAnimation(duration);
    }

    updateCenter() {
        this.center.x = this.syncViewBox.minX + this.syncViewBox.width / 2;
        this.center.y = this.syncViewBox.minY + this.syncViewBox.height / 2;
    }

    moveTo(x, y, zoom, duration = 500) {
        this.resetAnimation();

        this.animation.move.enabled = true;
        this.updateCenter();
        this.animation.move.start.x = this.center.x;
        this.animation.move.start.y = this.center.y;
        this.animation.move.start.zoom = this.zoomPercent;
        this.animation.move.end.x = x;
        this.animation.move.end.y = y;
        this.animation.move.end.zoom = zoom;

        this.startAnimation(duration);
    }

    animate(frameTime) {
        if (this.animation.lastFrame && frameTime) {
            let deltaTime = (frameTime - this.animation.lastFrame);
            this.animation.progress += deltaTime / this.animation.duration;
            if (this.animation.progress > 1) {
                this.animation.progress = 1;
                this.animation.enabled = false;
            }
            const easingProgress = this.easeInOutSine(this.animation.progress);

            if (this.animation.move.enabled) {
                this.center.x = this.animation.move.start.x * (1 - easingProgress) + this.animation.move.end.x * easingProgress;
                this.center.y = this.animation.move.start.y * (1 - easingProgress) + this.animation.move.end.y * easingProgress;
            }
            if (this.animation.viewBox.enabled) {
                this.viewBox.minX = this.animation.viewBox.start.minX * (1 - easingProgress) + this.animation.viewBox.end.minX * easingProgress;
                this.viewBox.minY = this.animation.viewBox.start.minY * (1 - easingProgress) + this.animation.viewBox.end.minY * easingProgress;
                this.viewBox.width = this.animation.viewBox.start.width * (1 - easingProgress) + this.animation.viewBox.end.width * easingProgress;
                this.viewBox.height = this.animation.viewBox.start.height * (1 - easingProgress) + this.animation.viewBox.end.height * easingProgress;
                this.syncViewBox = {...this.viewBox};
            }
        }
        if (frameTime) {
            this.animation.lastFrame = frameTime;
        }
        if (this.animation.enabled) {
            requestAnimationFrame(this.animate.bind(this));
        }
    }
}
