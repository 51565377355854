<template>
    <g v-if="floorLinks" class="layer layer-map-item-links">
        <g v-for="(link, index) in floorLinks" :key="'link-'+index">
            <line @click="$emit('link-selected', link)" @dblclick="$emit('link-split', $event, link)" class="map-item-link"
                  :class="[{ 'selected': link === linkSelected, 'hidden': !link.visible, 'optional': link.optional }, `rule-${link.navRule}`]"
                  :x1="link.masterItem.x" :y1="link.masterItem.y"
                  :x2="link.slaveItem.x" :y2="link.slaveItem.y" :style="lineStyle(link)"/>

            <text :x="link.getMiddlePoint().x" :y="link.getMiddlePoint().y" font-weight="bold" :font-size="pxToSVGScale(12)">
                ({{link.index}}) {{link.angle}}° - {{link.distance|round}}m [{{link.navCost|round}}]
            </text>
        </g>
    </g>
</template>

<script>
import MapMixin from "@/helpers/maps/map-mixin.js";
export default {
    name: `layer-map-item-links`,
    mixins: [MapMixin],
    props: {
        linkSelected: { type: Object },
        floorLinks: { type: Array }
    },
    inject: [`pxToSVGScale`],
    methods: {
        lineStyle(link) {
            return {
                strokeWidth: this.getScaleCSS(link.visible ? 12 : 7)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.layer-map-item-links {
    z-index: 0;

    .map-item-link {
        stroke: rgba(black, 0.25);
        stroke-linecap: round;

        &.hidden {
            stroke-width: calc(8 * var(--px-to-svg));
            stroke-dasharray: 0, calc(16 * var(--px-to-svg));
        }

        &.rule-recommended {
            stroke: rgba(green, 0.5);
        }

        &.rule-not-recommended {
            stroke: rgba(darkorange, 0.5);
        }

        &.rule-avoid {
            stroke: rgba(#ff6a00, 0.5);
        }

        &.rule-unavailable {
            stroke: rgba(red, 0.5);
        }
    }
}
</style>
