<template>
    <div v-if="map && floor && floor.id" class="map-container" :class="[{ 'editable': editable }, 'theme-' + map.style.theme, 'skin-' + map.style.skin]" :style="containerStyle">
        <e-interactive-svg ref="svg" :real-width="floor.realWidth" :real-height="floor.realHeight" :camera="camera" init-with-camera
                           :show-scale-legend="showScaleLegend" :background-image="floor.imagePath" :opacity="map.style.backgroundOpacity"
                           @item-moved="$emit(`item-moved`, $event)" :item-selected="itemSelected" @unselect="$emit('unselect')" @mousemove="$emit('mousemove', $event)">
            <template #defs>
                <slot name="defs"></slot>
                <linearGradient id="iconBG" x1="1" x2="0" y1="0" y2="1">
                    <stop offset="0%" stop-color="#495b65"/>
                    <stop offset="100%" stop-color="#61737b"/>
<!--                    <stop offset="0%" stop-color="#495b65"/>-->
<!--                    <stop offset="100%" stop-color="#61737b"/>-->
                </linearGradient>
            </template>

            <slot></slot>

            <template #pointer="mouse">
                <!-- debug pointer -->
                <!-- <circle :cx="mouse.x" :cy="mouse.y" :style="{r: getScaleCSS(20)}" fill="black"></circle> -->
                <slot name="pointer" v-bind="mouse"></slot>
            </template>
        </e-interactive-svg>

        <slot name="controls"></slot>


        <div class="floor-name-container" v-if="floor && map.floors.length > 1 && showFloorName">
            <div class="floor-name">
                {{tr(floor.getName())}}
            </div>
        </div>

        <aside v-if="!fullscreen && showControls" class="map-controls-panel">
            <template  v-if="!isMobileLayoutOrPreview">
                <div class="map-control">
                    <span class="floating-label">Zoom in</span>
                    <b-btn variant="variant" @click="$refs.svg.updateZoom(10)">
                        <i class="fas fa-magnifying-glass-plus"/>
                    </b-btn>
                </div>
                <div class="map-control">
                    <span v-if="!isMobileLayoutOrPreview" class="floating-label">Zoom out</span>
                    <b-btn variant="variant" @click="$refs.svg.updateZoom(-10)">
                        <i class="fas fa-magnifying-glass-minus"/>
                    </b-btn>
                </div>
            </template>
            <div class="map-control">
                <span v-if="!isMobileLayoutOrPreview" class="floating-label">Re-center</span>
                <b-btn variant="variant" @click="$emit('reset-camera'); $refs.svg.resetView()">
                    <i class="fa-solid fa-crosshairs"/>
                    </b-btn>
            </div>

            <div v-if="isDebugVisible" class="zoom-display">
                {{camera.zoomLevel}} - {{camera.zoomPercent|round}}%
            </div>
        </aside>

        <div v-if="$slots.legend" class="map-legend">
            <slot name="legend"></slot>
        </div>
    </div>
</template>

<script>
import MapMixin from "@/helpers/maps/map-mixin.js";
import EInteractiveSvg from "../../../vue-components/components/e-interactive-svg.vue";


export default {
    name: `map-container`,
    mixins: [MapMixin],
    components: {EInteractiveSvg},
    props: {
        camera: {type: Object},
        itemSelected: { type: Object },
        map: { type: Object },
        floor: { type: Object },
        editable: { type: Boolean },
        fullscreen: { type: Boolean },
        showFloorName: { type: Boolean },
        showControls: { type: Boolean },
        showScaleLegend: {type: Boolean}
    },
    data() {
        return {
        }
    },
    computed: {
        containerStyle() {
            return {
                background: this.map.style.background,
                "--map-icon-size": this.map.style.iconSize + `px`,
                "--map-label-size": (12 * this.map.style.uiScale) + `px`
            };
        }
    }
}
</script>

<style lang="scss">
.map-container {
    position: relative;
    margin-left: var(--map-container-left-padding);
    width: calc(100% - var(--map-container-left-padding));
    min-width: 300px;
    min-height: 300px;
    //background-image: linear-gradient(to top, rgb(127, 129, 82), rgb(36, 143, 171));
    background: #eee;
    overflow: hidden;
    user-select: none;

    flex: 1;
    height: calc(100vh - var(--menu-height));

    --map-control-size: 50px;
    --map-icon-size: 24px;
    --map-label-size: 18px;

    --map-primary-color: #005fe5;
    //--map-primary-color: #1b8fad;
    --map-secondary-color: #3bab31;

    .interactive-svg {
        min-height: calc(80vh);
    }

    .map-legend {
        position: absolute;
        z-index: 1;
        bottom: 50px;
        left: 0;
    }

    .floor-name-container {
        position: absolute;
        z-index: 1;
        top: 10px;
        left: 10px;
        pointer-events: none;

        .floor-name {
            background: rgba(#000, 0.9);
            color: white;
            border-radius: 50px;
            padding: 10px 20px;
        }
    }

    .map-controls-panel {
        position: absolute;
        bottom: calc(10px + var(--page-bottom-margin));
        right: 10px;
        text-align: right;

        &:hover span {
            opacity: 1;
            margin-right: 0;
        }

        .floating-label {
            display: inline-block;
            opacity: 0;
            margin-right: 10px;
            font-weight: 500;
            text-align: right;
            transition: opacity 250ms, margin-right 250ms;
            pointer-events: none;
            color: black;
            text-shadow: 2px 2px 5px white;
        }

        .btn {
            display: inline-block;
            width: var(--map-control-size);
            height: var(--map-control-size);
            font-size: x-large;
            margin: 5px 0;
            background-color: #fff;
            color: #333;
            box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);

            &:hover {
                background-color: rgb(36, 94, 117);
                color: white;
            }

            &:active {
                background-color: rgb(36, 94, 117);
                color: white;
            }

            &:focus, &:visited {
                background-color: rgb(36, 94, 117);
                color: white;
            }

            &.btn-toggled {
                color: white;
                background-color: #369676;
            }
        }

        @container(max-width: 600px) {
            bottom: auto;
            top: 60px;
            --map-control-size: 40px;

            .btn {
                display: inline-block;
                padding: 0;
            }
        }
    }

    .zoom-display {
        position: fixed;
        top: 5px;
        left: 5px;
        background: white;
        font-size: 10pt;
        font-weight: 500;
        white-space: nowrap;
        color: #333;
        padding: 5px 10px;
        border-radius: 10px;
    }

    --selection-color: 255, 165, 0;

    .item-selected {
        &.type-shelf,
        &.zone {
            rect {
                fill: rgba(255, 210, 4, 0.2);
                stroke: rgb(var(--selection-color));
            }

            text {
                fill: #cf8600;
            }
        }

        circle.selector {
            fill: rgba(255, 182, 0, 0.3) !important;
            stroke: rgb(var(--selection-color)) !important;
        }

        .radius {
            fill: rgba(255, 182, 0, 0.1);
            stroke: rgba(255, 165, 0, 0.5);

            &.short-range {
                fill: rgba(255, 182, 0, 0.2);
                stroke: var(--selection-color);
            }
        }
    }
}

//.layout-mobile {
//    .map-controls-panel {
//        right: auto;
//        left: calc(var(--page-padding-left) + 10px);
//        top: calc(var(--page-padding-top) + 130px);
//    }
//}

.layout-kiosk {
    .map-container {
        height: var(--kiosk-map-height) !important;
    }

    .map-controls-panel {
        left: 20px;
        right: auto;
        bottom: 120px;

        .floating-label {
            display: inline-block;
            position: absolute;
            left: 60px;
            width: 150px;
            text-align: left;
            line-height: var(--map-control-size);
            margin-right: 10px;
        }
    }

    .floor-name-container {
        //left: 0;
        //width: 100%;
        //pointer-events: none;
        //display: flex;
        //align-items: center;
        //justify-content: center;

        .floor-name {
            font-size: 16pt;
        }
    }
}
</style>
