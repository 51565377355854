import FileLibraryHelper from "@/helpers/cms/FileLibraryHelper.js";
import NavFloorPath from "@/helpers/maps/NavFloorPath.js";
import MapInstructions from "./MapInstructions.js";

/**
 * Create list of instructions based on Pathfinder result
 */
export default class NavPath {
    constructor(path) {
        this.enabled = false;
        this.setPath(path);
    }

    /**
     * Find the closest navigation path using Dijkstra's algorithm
     * @param path {Object}
     * @param endPoint {MapItem}
     */
    setPath(path) {
        this.path = path;
        if (!this.path) {
            this.enabled = false;
            return false;
        }

        this.floors = {};
        this.floorPaths = [];

        this.instructions = [];
        let totalDistance = 0;
        for (let i = 0; i < this.items.length; i++) {
            const previousItem = i > 0 ? this.items[i - 1] : null;
            const currentItem = this.items[i];
            const nextItem = i < this.items.length - 1 ? this.items[i + 1] : null;
            const navInstruction = new MapInstructions(this.instructions.length, previousItem, currentItem, nextItem);

            if (navInstruction.isVisible()) {
                this.instructions.push(navInstruction);
                totalDistance += navInstruction.distance;
            }/* else {
                console.info(`ignored`);
                console.dir(navInstruction);
            }*/
        }

        for (let i = 0; i < this.instructions.length - 1; i++) {
            let navInstruction = this.instructions[i];
            let nextNavInstruction = this.instructions[i + 1];


            if (navInstruction.currentItem.stopRule !== `always`) {
                if (navInstruction.index > 0) {
                    if ((navInstruction.currentItem.stopRule === `never`) ||
                        // (navInstruction.nextItem?.type === `elevator`) ||
                        (navInstruction.direction === `forward` && navInstruction.distance + nextNavInstruction.distance < 50)) {
                        // console.log(navInstruction.currentItem.name);
                        // console.log(navInstruction.direction);
                        // console.log(navInstruction.deltaAngle);
                        // console.dir(navInstruction);

                        nextNavInstruction.mergeWith(navInstruction);
                        this.instructions.removeItemAtIndex(i);
                        // console.log(navInstruction.direction);
                        // console.log(navInstruction.deltaAngle);
                        i--;
                    }
                }
            }

            if (!this.floors[navInstruction.fromFloor.id]) {
                this.floors[navInstruction.fromFloor.id] = {
                    floor: navInstruction.fromFloor,
                    instructions: [navInstruction]
                }
            }
        }

        if (this.instructions.length > 0) {
        // second pass after merging instructions
            for (let i = 0; i < this.instructions.length - 1; i++) {
                this.instructions[i].setNextInstruction(this.instructions[i + 1]);
            }
            this.instructions[this.instructions.length - 1].setArrival(this.end);
        }

        this.startFloor = this.instructions[0].toFloor;
        this.endFloor = this.instructions[this.instructions.length - 1].toFloor;

        this.floorPaths.push(new NavFloorPath(this, this.startFloor));
        if (this.startFloor !== this.endFloor) {
            this.floorPaths.push(new NavFloorPath(this, this.endFloor));
        }

        this.floorDirection = `same-floor`;
        if (this.startFloor.level < this.endFloor.level) {
            this.floorDirection = `up`;
        } else if (this.startFloor.level > this.endFloor.level) {
            this.floorDirection = `down`;
        }

        this.previewImage = this.path.endPoint.image;

        // In some cases the end point is not a physical location, so take image from the last instruction instead
        if (this.instructions[this.instructions.length - 1].image) {
            this.previewImage = this.instructions[this.instructions.length - 1].image;
        }
        this.previewImage = FileLibraryHelper.getCacheUrl(this.previewImage);

        this.distance = Math.ceil(totalDistance);// in meters
        this.duration = Math.ceil((this.distance / 0.5) / 60);// Avg walking speed of 0.5m/s so duration is rounded up in minutes

        this.enabled = true;
    }

    get start() {
        return this.path.startPoint;
    }

    get end() {
        return this.path.endPoint;
    }

    get items() {
        return this.path.items;
    }

    get links() {
        return this.path.links;
    }

    getFloorPath(floorId) {
        return this.floorPaths.find(p => p.floor.id === floorId);
    }
}
