export default {
    computed: {
        isDebugVisible() {
            return this.$store.state.mapEditor.debug.enabled === true;
        },
        isMapDebugVisible() {
            return this.$store.state.mapEditor.debug.enabled === true && this.$store.state.mapEditor.debug.map === true;
        },
        debugSettings() {
            return this.$store.state.mapEditor.debug;
        },
        isPanoDebugVisible() {
            return this.$store.state.mapEditor.debug.enabled === true && this.$store.state.mapEditor.debug.pano === true;
        },
        isPathDebugVisible() {
            return this.$store.state.mapEditor.debug.enabled === true && this.$store.state.mapEditor.debug.path === true;
        },
        currentMap() {
            return this.$store.state.mapEditor.map;
        },
        currentFloor() {
            return this.$store.state.mapEditor.floor;
        }
    },
    methods: {
        getScaleCSS(sizeInPixel) {
            return `calc(${sizeInPixel}px * var(--px-to-svg))`;
        },
        pxToMeters(pxCoordinate) {
            return pxCoordinate * this.$store.state.mapEditor.floor.scale / 100;
        },
        metersToPx(mCoordinate) {
            return mCoordinate / this.$store.state.mapEditor.floor.scale * 100;
        }
    }
}
