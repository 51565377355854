<template>
    <svg ref="svg" class="e-interactive-svg" :view-box.camel="viewBoxString" preserveAspectRatio="xMidYMid"
         @wheel.prevent="onMouseWheel" @pointerdown="startDragging" @touchmove="onTouchMove" @mousemove="onMouseMove"
         @pointerup="stopDragging" @pointerleave="stopDragging" @pointercancel="stopDragging"
         xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">

        <defs>
            <slot name="defs"></slot>
        </defs>

        <image @pointerdown.self="$emit('unselect')" v-if="backgroundImage" :href="backgroundImage" :width="realWidth" :height="realHeight" x="0" y="0" :opacity="opacity"></image>

        <slot></slot>

        <slot name="pointer" v-bind="{x: mouseCoordinates.x, y: mouseCoordinates.y, zoom: zoomPercent}"></slot>

        <!-- Debug tool only -->
        <!--        <g class="debug-pointer" style="pointer-events: none">&ndash;&gt;-->
        <!--            <circle class="debug-point" :cx="pinchCenter.x" :cy="pinchCenter.y" :r="pxToSVGScale(5)"></circle>-->
        <!--        </g>-->
        <!--        <g class="debug-pointer" style="pointer-events: none">-->
        <!--            <circle class="debug-point" :cx="mouseCoordinates.x" :cy="mouseCoordinates.y" :r="pxToSVGScale(3)"></circle>-->
        <!--            <text class="debug-text" :font-size="pxToSVGScale(14)" :x="mouseCoordinates.x" :y="mouseCoordinates.y">{{ mouseCoordinates.x|round }}, {{ mouseCoordinates.y|round }}</text>-->
        <!--        </g>-->
        <g class="scale-legend" v-if="showScaleLegend">
            <text class="scale-title" :x="scaleLine.x1" :y="scaleLine.y1 - scaleLine.strokeWidth * 2" fill="white" :font-size="pxToSVGScale(20)">{{ scaleLine.unit }}m</text>
            <line class="scale-bar" :x1="scaleLine.x1" :y1="scaleLine.y1" :x2="scaleLine.x2" :y2="scaleLine.y2" :stroke-width="scaleLine.strokeWidth" stroke="white"></line>
        </g>
    </svg>
</template>

<script>
import {computed} from "vue";
import SvgCamera from "../helpers/SvgCamera.js";

// e-interactive-svg
export default {
    name: `e-interactive-svg`,
    props: {
        // "v-models" with .sync
        camera: {type: Object},
        view: {type: String},
        zoom: {type: Number, default: 100},
        // other props
        itemSelected: {type: Object},
        backgroundImage: String,
        realWidth: {type: Number, required: true},
        realHeight: {type: Number, required: true},
        showScaleLegend: {type: Boolean},
        initWithCamera: {type: Boolean},
        opacity: {type: Number, default: 0.5}
    },
    data() {
        return {
            viewBox: {minX: 0, minY: 0, width: this.realWidth, height: this.realHeight},
            zoomPercent: 100,
            zoomLevel: 5,
            mapCamera: null,
            // needed for calculus
            refPoint: null,
            svgWidth: 0,
            svgHeight: 0,
            svgInvertedMatrix: null,
            initDeepWatchers: false,// they are triggered immediately by default
            pxToSvgScaleRatio: 1,
            draggingOffsetX: 0,
            draggingOffsetY: 0,
            // mouse, touch events
            mouseCoordinates: {x: 0, y: 0},
            startMousePoint: {x: 0, y: 0},
            startViewBox: null,
            dragging: false,
            pinchCenter: {x: 0, y: 0},
            pinchDistance: null,
            dragItemCoordinates: {x: 0, y: 0}
        }
    },
    provide() {
        return {
            pxToSvgScaleRatio: computed(() => this.pxToSvgScaleRatio),
            zoom: computed(() => this.zoomPercent),
            zoomLevel: computed(() => this.zoomLevel),
            pxToSVGScale: this.pxToSVGScale,
            getScaleCSS: this.getScaleCSS
        }
    },
    computed: {
        viewBoxString() {
            return `${this.viewBox.minX} ${this.viewBox.minY} ${this.viewBox.width} ${this.viewBox.height}`
        },
        scaleLine() {
            const padding = this.pxToSVGScale(20);

            let unit = 1;
            while (unit < this.pxToSVGScale(50)) {
                unit *= 10;
            }

            const x1 = this.viewBox.minX + padding;
            const y = this.viewBox.minY + this.viewBox.height - padding;
            return {
                x1: x1,
                y1: y,
                x2: x1 + unit,
                y2: y,
                strokeWidth: this.pxToSVGScale(5),
                unit: unit
            }
        }
    },
    created() {
        if (this.camera) {
            this.mapCamera = this.camera;
        } else {
            this.mapCamera = new SvgCamera(this.realWidth, this.realHeight);
        }
    },
    mounted() {
        if (this.initWithCamera) {
            this.centerView(this.mapCamera.center.x, this.mapCamera.center.y, this.mapCamera.zoomPercent);
        } else {
            this.watchZoom();
            this.watchView();
        }

        // Fix issue with first pan/zoom not being correct
        this.$nextTick(() => {
            this.updateSVGMatrix();
            this.initDeepWatchers = true;
        });

        window.addEventListener(`resize`, this.updateSVGMatrix);
    },
    beforeDestroy() {
        window.removeEventListener(`resize`, this.updateSVGMatrix);
    },
    methods: {
        computeRatios() {
            this.zoomPercent = 100 * this.realWidth / this.viewBox.width;
            this.$emit(`update:view`, this.viewBoxString);
            this.$emit(`update:zoom`, this.zoomPercent);

            if (this.svgWidth < this.svgHeight) {
                this.pxToSvgScaleRatio = this.viewBox.width / this.svgWidth;
            } else {
                this.pxToSvgScaleRatio = this.viewBox.height / this.svgHeight;
            }

            this.zoomLevel = this.getZoomLevel();

            this.mapCamera.pxToSvgScaleRatio = this.pxToSvgScaleRatio;
            this.mapCamera.pxDensity = this.getPxDensity();
            this.mapCamera.zoomLevel = this.zoomLevel;
            this.mapCamera.zoomPercent = this.zoomPercent;
        },
        // this function is laggy if done a lot of time per second (due to SVG matrix)
        updateSVGMatrix() {
            const bbox = this.$refs.svg.getBoundingClientRect();
            this.svgWidth = bbox.width;
            this.svgHeight = bbox.height;

            this.computeRatios();

            this.refPoint = this.$refs.svg.createSVGPoint();
            this.svgInvertedMatrix = this.$refs.svg.getScreenCTM().inverse();

            this.draggingOffsetX = 0;
            this.draggingOffsetY = 0;
        },
        // view methods
        setViewBox(minX, minY, width, height, updateMatrix = true) {
            this.viewBox = {minX: +minX, minY: +minY, width: +width, height: +height};
            this.mapCamera.syncViewBox = this.viewBox;
            if (updateMatrix) {
                this.updateSVGMatrix();
            } else {
                this.computeRatios();
            }
        },
        setView(minX, minY, maxX, maxY, paddingInPx = 0, updateMatrix = true) {
            const p = this.pxToSVGScale(paddingInPx);

            let width = maxX - minX + 2 * p;
            let height = maxY - minY + 2 * p;

            if (height > width) {
                width = height * this.viewBox.width / this.viewBox.height;
                minY = minY - p;
                minX = minX + (maxX - minX) / 2 - width / 2;
            } else {
                height = width * this.viewBox.height / this.viewBox.width;
                minX = minX - p;
                minY = minY + (maxY - minY) / 2 - height / 2;
            }

            this.setViewBox(minX, minY, width, height, updateMatrix);
        },
        setViewContainingPoints(points, paddingInPx = 0, updateMatrix = true) {
            if (points.length === 0) {
                throw new Error(`points array is empty`);
            } else if (points.length < 2) {
                throw new Error(`points array only containing one point, use centerView instead`);
            }

            let minX = points[0].x;
            let minY = points[0].y;
            let maxX = points[0].x;
            let maxY = points[0].y;
            for (let i = 1; i < points.length; i++) {
                minX = Math.min(points[i].x, minX);
                minY = Math.min(points[i].x, minY);
                maxX = Math.max(points[i].x, maxX);
                maxY = Math.max(points[i].x, maxY);
            }

            this.setView(minX, minY, maxX, maxY, paddingInPx, updateMatrix);
        },
        resetView() {
            if (this.initWithCamera) {
                this.centerView(this.mapCamera.center.x, this.mapCamera.center.y, this.mapCamera.zoomPercent);
            } else {
                this.setViewBox(0, 0, this.realWidth, this.realHeight);
            }
        },
        centerView(x, y, zoomPercent = null, updateMatrix = true) {
            if (zoomPercent === null) {
                zoomPercent = this.zoomPercent;
            }

            if (zoomPercent <= 0) {
                throw new Error(`zoomPercent must be > 0`);
            }

            const zoomFactor = zoomPercent / 100;

            const width = this.realWidth / zoomFactor;
            const height = this.realHeight / zoomFactor;
            const minX = x - width / 2;
            const minY = y - height / 2;
            this.setViewBox(minX, minY, width, height, updateMatrix);
        },
        updateZoom(deltaPercent, centerOfZoom = null, updateMatrix = true) {
            if (deltaPercent <= -100) {
                throw new Error(`deltaPercent must be > -100`);
            }

            // If current zoomPercent is 200% and deltaPercent is 10%, new zoom will be 220% (+10% of 200%) and not 210%
            // With addition it would feel very slow to zoom in
            this.setZoom(this.zoomPercent * (1 + deltaPercent / 100), centerOfZoom, updateMatrix);
        },
        setZoom(zoomPercent, centerOfZoom = null, updateMatrix = true) {
            if (zoomPercent > this.mapCamera.maxZoom || zoomPercent < this.mapCamera.minZoom) {
                return;
            }

            if (zoomPercent <= 0) {
                throw new Error(`zoomPercent must be > 0`);
            }

            const zoomFactor = zoomPercent / 100;

            let width = this.realWidth / zoomFactor;
            let height = this.realHeight / zoomFactor;

            // default center of zoom is center of the viewBox
            if (centerOfZoom === null) {
                centerOfZoom = {
                    x: this.viewBox.minX + this.viewBox.width / 2,
                    y: this.viewBox.minY + this.viewBox.height / 2
                }
            }

            const zoomDelta = this.realWidth / (zoomFactor * this.viewBox.width);

            const offsetX = centerOfZoom.x - this.viewBox.minX;
            const offsetY = centerOfZoom.y - this.viewBox.minY;

            const minX = centerOfZoom.x - offsetX * zoomDelta;
            const minY = centerOfZoom.y - offsetY * zoomDelta;

            this.setViewBox(minX, minY, width, height, updateMatrix);
        },
        translate(dX, dY, updateMatrix = true) {
            this.translateTo(this.viewBox.minX + dX, this.viewBox.minY + dY, updateMatrix);
        },
        translateTo(x, y, updateMatrix = true) {
            let minX = this.mapCamera.clampX(x);
            let minY = this.mapCamera.clampY(y);

            this.setViewBox(minX, minY, this.viewBox.width, this.viewBox.height, updateMatrix);
        },
        // events
        startDragging(event) {
            this.dragging = true;
            this.startMousePoint = this.mouseEventToPoint(event);
            this.startViewBox = {...this.viewBox};
            this.pinchDistance = null;

            if (this.itemSelected) {
                this.dragItemCoordinates.x = this.itemSelected.x;
                this.dragItemCoordinates.y = this.itemSelected.y;
            }
        },
        stopDragging() {
            this.dragging = false;
            this.pinchDistance = null;
            this.updateSVGMatrix();
        },
        onTouchMove(event) {
            if (event.touches && event.touches.length === 2) {
                event.preventDefault();

                const finger1 = this.getEventCoord(event.touches[0]);
                const finger2 = this.getEventCoord(event.touches[1]);

                const currentDistance = Math.hypot(finger2.x - finger1.x, finger2.y - finger1.y);

                if (this.pinchDistance === null) {
                    this.pinchDistance = currentDistance;
                    // We don't reset matrix because of lag (too much touchmove event), so the pageCoordToPoint() is only correct the first time, so we assume the pinch center stay the same during the whole gesture
                    this.pinchCenter = this.pageCoordToPoint({
                        x: (finger1.x + finger2.x) / 2,
                        y: (finger1.y + finger2.y) / 2
                    });
                    return;
                }

                const pinchZoom = 100 * (currentDistance - this.pinchDistance) / this.pinchDistance;
                this.pinchDistance = currentDistance;

                this.updateZoom(pinchZoom, this.pinchCenter, false);
            } else {
                this.onMouseMove(event);
            }
        },
        onMouseMove(event) {
            const mousePoint = this.mouseEventToPoint(event);
            this.mouseCoordinates = {x: mousePoint.x + this.draggingOffsetX, y: mousePoint.y + this.draggingOffsetY};
            this.$emit(`mousemove`, this.mouseCoordinates);

            if (this.dragging) {
                if (this.itemSelected) {
                    this.$emit(`item-moved`, this.mouseCoordinates);
                    return;
                }

                let minX = this.mapCamera.clampX(this.startViewBox.minX + this.startMousePoint.x - mousePoint.x);
                let minY = this.mapCamera.clampY(this.startViewBox.minY + this.startMousePoint.y - mousePoint.y);

                this.draggingOffsetX += minX - this.viewBox.minX;
                this.draggingOffsetY += minY - this.viewBox.minY;

                // here we should resetSVGMatrix but it causes big lags. Instead, we use draggingOffsets to correct mouse position.
                this.translateTo(minX, minY, false)
            }
        },
        onMouseWheel(event) {
            const zoomPercent = -Math.sign(event.deltaY) * this.mapCamera.zoomStep;
            this.updateZoom(zoomPercent, this.mouseEventToPoint(event));
        },
        // helpers
        getEventCoord(event) {
            if (event.touches) {
                return {x: event.touches[0].clientX || event.touches[0].pageX, y: event.touches[0].clientY || event.touches[0].pageY};
            }
            return {x: event.clientX || event.pageX, y: event.clientY || event.pageY};
        },
        pageCoordToPoint(coord) {
            this.refPoint.x = coord.x;
            this.refPoint.y = coord.y;
            const coordinates = this.refPoint.matrixTransform(this.svgInvertedMatrix);

            return {
                x: coordinates.x,
                y: coordinates.y
            };
        },
        mouseEventToPoint(event) {
            const coord = this.getEventCoord(event);
            return this.pageCoordToPoint(coord);
        },
        pxToSVGScale(sizeInPx) {
            return sizeInPx * this.pxToSvgScaleRatio;
        },
        getScaleCSS(sizeInPixel) {
            return `calc(${sizeInPixel}px * var(--px-to-svg))`;
        },
        getPxDensity() {
            // use 860 width/height for the map container as a reference (map editor with 1920x1080px screen)
            const ratio = Math.min(this.svgWidth, this.svgHeight) / 860;
            return this.realWidth * 860 / ratio / this.zoomPercent / 100;
        },
        /**
         * Zoom level is a normalized value between 1 and 10,
         * 12 means points are really spaced
         * 8 is the level where the map becomes comfortable to read
         * 4 gives a good overview, the map can be hard to read
         * 0 means really far and really hard to read, only the most important points should be at this level
         * @returns {number}
         */
        getZoomLevel() {
            // simple remap from 5-50 to 0-10
            // return (x - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
            // note value is inverted to make 1 the minimum size
            return Math.max(0, Math.round(12 - Math.min(12, (this.getPxDensity() - 5) * (12 - 1) / (50 - 5) + 1)));
        },
        // v-models
        watchZoom() {
            this.setZoom(this.zoom);
        },
        watchView() {
            if (!this.view) {
                return;
            }

            const tmp = this.view.split(` `);
            if (tmp.length !== 4) {
                throw new Error(`wrong view prop format, must be formatted with 4 Numbers separated by one space: "minX minY width height"`)
            }

            this.setViewBox(tmp[0], tmp[1], tmp[2], tmp[3]);
        }
    },
    watch: {
        "mapCamera.center": {
            handler() {
                if (this.initDeepWatchers) {
                    this.centerView(this.mapCamera.center.x, this.mapCamera.center.y);
                }
            },
            deep: true
        },
        "mapCamera.viewBox": {
            handler() {
                if (this.initDeepWatchers) {
                    this.setViewBox(+this.mapCamera.viewBox.minX, +this.mapCamera.viewBox.minY, +this.mapCamera.viewBox.width, +this.mapCamera.viewBox.height, true);
                }
            },
            deep: true
        },
        "mapCamera.zoomPercent": {
            handler() {
                if (this.initDeepWatchers) {
                    this.setZoom(this.mapCamera.zoomPercent);
                }
            },
            deep: true
        },
        zoom() {
            this.watchZoom();
        },
        view() {
            this.watchView();
        },
        realWidth() {
            this.updateSVGMatrix();
        },
        realHeight() {
            this.updateSVGMatrix();
        }
    }
}
</script>

<style lang="scss" scoped>
.e-interactive-svg {
    width: 100%;
    height: 100%;
    touch-action: none;

    --px-to-svg: v-bind(pxToSvgScaleRatio);

    :deep(.fixed-px-size) {
        transform: scale(var(--px-to-svg)) !important;
    }

    .scale-title {
        alignment-baseline: middle;
        user-select: none;
        fill: white;
        text-align: left;
        font-weight: 500;
        text-shadow: 2px 2px 5px black;
    }

    .scale-bar {
        --shadow-length: calc(2px * var(--px-to-svg));
        filter: drop-shadow(
                calc(2px * var(--px-to-svg)) calc(2px * var(--px-to-svg)) calc(5px * var(--px-to-svg)) rgba(0, 0, 0, 0.7));
    }

    .fixed-svg-size {
        transform: scale(2) !important;
    }
}
</style>
