<template>
    <dialog class="virtual-keypad" :class="['theme-' + theme, { 'visible': visible, 'fixed': fixed }]">
        <div :class="keyboardClass" :disabled="disabled"/>
    </dialog>
</template>

<script>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";

export default {
    name: `virtual-keypad`,
    props: {
        keyboardClass: { default: `simple-keyboard`, type: String },
        fixed: { type: Boolean },
        disabled: { type: Boolean },
        maxLength: { type: Number },
        value: { type: String },
        theme: { type: String, default: `light` } // light | dark | transparent
    },
    computed: {
        input: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
                this.keyboard.setInput(value);
            }
        }
    },
    data() {
        return {
            keyboard: null,
            visible: true
        }
    },
    mounted() {
        this.keyboard = new Keyboard(this.keyboardClass, {
            onChange: this.onChange,
            onKeyPress: this.onKeyPress,
            maxLength: this.maxLength,
            layout: {
                default: [
                    `1 2 3`,
                    `4 5 6`,
                    `7 8 9`,
                    `0 {bksp}`
                ]
            },
            display: {
                "{escape}": `Esc`,
                "{tab}": `⇥`,
                "{bksp}": `<i class='fas fa-delete-left' style="font-size: 20pt;"/>`,
                "{shift}": `⇧`,
                "{space}": ` `,
                "{del}": this.tr(`Clear|Effacer`),
                "{enter}": this.tr(`Send|Envoyer`) + ` <i class='fas fa-paper-plane-top'/>`,
                "{capslock}": `⇪`,
            }
        });
    },
    methods: {
        onChange(input) {
            this.$emit(`change`, input);
        },
        onKeyPress(button) {
            this.$emit(`keyPress`, button);

            /**
             * If you want to handle the shift and caps lock buttons
             */
            if (button === `{shift}` || button === `{lock}`) this.handleShift();
        },
        handleShift() {
            let currentLayout = this.keyboard.options.layoutName;
            let shiftToggle = currentLayout === `default` ? `shift` : `default`;

            this.keyboard.setOptions({
                layoutName: shiftToggle
            });
        },
        clear() {
            this.keyboard.clearInput();
        }
    }
};
</script>

<style lang="scss" scoped>
.virtual-keypad {
    position: relative;
    width: 400px;
    max-width: calc(100% - 40px);
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0 20px 50px rgba(black, 0.2);
    background: #ececec;
    padding: 10px;
    display: block;
    border: 2px solid #bbb;
    font-size: 15pt;
    font-weight: 500;
    letter-spacing: 1.5px;

    ::v-deep .hg-button {
        height: 70px;

        &.hg-button-space {
            padding-top: 0;
            font-size: 25pt;
            flex: 5;
        }

        &.hg-button-enter {
            background: var(--accent-color);
            color: white;
        }
    }

    &.theme-dark {
        background: #222;
        border-color: #111;

        .simple-keyboard {
            background: inherit;

            ::v-deep .hg-button {
                background: #666;
                border-bottom-color: #444;
                color: #eee;
            }
        }
    }

    &.theme-transparent {
        background: transparent;
        box-shadow: none;
        border: none;
        color: #777;

        .simple-keyboard {
            background: transparent;

            ::v-deep .hg-button {
                border-radius: 10px;
                border-bottom: 3px solid #b5b5b5;
                box-shadow: 0 0 5px -2px rgba(0, 0, 0, .4);
            }
        }
    }

    &.fixed {
        position: fixed;
        z-index: 2000;
        left: calc(50% - 540px);
        margin: 0;

        @container (max-width: 1080px) {
            & {
                left: 20px;
            }
        }

        @media (max-height: 1200px) {
            & {
                top: auto;
                bottom: 20px;
            }
        }

        @media (min-height: 1200px) {
            & {
                top: 700px;
                left: calc(50% - 400px);
            }
        }
    }
}
</style>
