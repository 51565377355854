export default class NavFloorPath {
    constructor(navPath, floor) {
        this.floor = floor;
        this.items = [];
        this.path = `M`;
        this.distance = 0; // in meters
        this.firstItem = null;
        this.lastItem = null;

        let lastItem = null;

        for (let link of navPath.links) {
            if (link.floorId === floor.id && link.visible) {
                lastItem = this.addPoint(link.getStartItem(), lastItem);
                lastItem = this.addPoint(link.getEndItem(), lastItem);
            }
        }
        this.duration = Math.max(this.distance * 0.035, 3); // in seconds with duration of minimum duration to avoid flashy animation
    }

    addPoint(item, lastItem) {
        if (lastItem === item) {
            return lastItem;
        }
        if (this.path !== `M`) {
            this.path += ` L`;
        }
        this.items.push(item);
        if (!this.firstItem) {
            this.firstItem = item;
        }
        this.lastItem = item;
        if (lastItem !== null) {
            this.distance += Math.sqrt(Math.pow(lastItem.x - item.x, 2) + Math.pow(lastItem.y - item.y, 2));
        }
        lastItem = item;
        this.path += ` ${item.x} ${item.y}`;
        return lastItem;
    }
}
