/**
 * Implementation of Dijkstra's Algorithm to find shortest path
 * Includes navigation through multiple floors
 */
export default class Pathfinder {
    constructor(map) {
        this.map = map;
        this.nodes = {};
        for (let item of map.items) {
            let node = {};
            for (let link of item.links) {
                node[link.getOtherItem(item).index] = link.distance + link.navCost + item.navCost;
            }
            this.nodes[item.index] = node;
        }
    }

    findClosestItem(startPoint, items) {
        if (!items)
            return null;

        if (items.length === 1 || !startPoint)
            return items[0];

        let shortestPath = null;
        for (let item of items) {
            const path = this.findClosestPath(startPoint, item);
            if (!path)
                continue;

            if (!shortestPath || shortestPath.totalDistance > path.totalDistance) {
                shortestPath = path;
            }
        }
        if (shortestPath) {
            return shortestPath.endPoint;
        } else {
            return null;
        }
    }

    /**
     * Find the closest navigation path using Dijkstra's algorithm
     * @param startPoint {MapItem}
     * @param endPoint {MapItem}
     */
    findClosestPath(startPoint, endPoint) {
        if (startPoint.links.length === 0 || endPoint.links.length === 0) {
            console.log(startPoint, endPoint);
            console.error(`Not reachable`);
            return false;
        }

        let path = {
            startPoint,
            endPoint,
            startNode: this.nodes[startPoint.index],
            endNode: this.nodes[endPoint.index],
            parents: {},
            totalDistance: 0
        }
        path.distances = Object.assign({}, path.startNode);

        let history = [];

        let node = this._closestItem(path.startNode, history);

        let index = 0;
        while (node) {
            let distance = path.distances[node];
            if (!distance) {
                distance = 0;
            }
            let children = this.nodes[node];
            for (let n in children) {
                let newDistance = distance + children[n];
                if (!path.distances[n] || path.distances[n] > newDistance) {
                    path.distances[n] = newDistance;
                    path.parents[n] = node;
                }
            }
            history.push(node);
            node = this._closestItem(path.distances, history);

            if (++index > 1000) {
                console.error(`Query above 500, aborting...`);
                node = null;
                break;
            }
        }

        // Nav Path
        path.links = [];
        path.items = [endPoint];
        path.distances[path.endNode.index] = Infinity;

        let parent = path.parents[endPoint.index];
        let lastNavItem = endPoint;
        // From end to start
        while (parent) {
            const navItem = this.map.items.find(item => item.index === parent);
            path.items.push(navItem);

            const navLink = this.map.links.find(link => link.isLinkBetween(lastNavItem, navItem));
            if (!navLink) {
                console.error(`Could not find link between ${lastNavItem.id} and ${navItem.id}`);
                return false;
            } else {
                path.links.push(navLink);
                navLink.setEndPoint(lastNavItem);
            }

            lastNavItem = navItem;
            parent = path.parents[parent];
        }

        // add link with start point
        const startLink = this.map.links.find(link => link.isLinkBetween(lastNavItem, startPoint));
        path.links.push(startLink);
        startLink.setEndPoint(lastNavItem);
        path.items.push(startPoint);

        // revert order because we added items from start to the destination order
        path.links.reverse();
        path.items.reverse();

        path.totalDistance = path.distances[endPoint.index];
        return path;
    }

    _closestItem(distances, history) {
        let minDistance = Infinity;
        let closestNode = null;
        for (let node in distances) {
            if (!history.includes(node) && distances[node] < minDistance) {
                minDistance = distances[node];
                closestNode = node;
            }
        }
        return closestNode;
    }
}
